import React from 'react';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import PhoneIcon from '@mui/icons-material/Phone';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    border: '1px solid #dadde9',
  },
}))(Tooltip);

type Props = {
  ignoreText?: boolean
}

const Special = ({ ignoreText }: Props) => {
  const text = ignoreText ? '' : 'Special Order';

  return (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            <div>Special Order</div>
            This item is special order only. Please contact us at (902) 343-3569.
          </React.Fragment>
        }
      >
        <Button startIcon={<PhoneIcon />} className='delivery-description-button'>{text}</Button>
      </HtmlTooltip>
    </>
  )
}

export default Special