import ModifyCartButtons from './ModifyCartButtons';
import TotalsPanel from './TotalsPanel';

type Props = {
  readonly?: boolean,
  hideInactive?: boolean,
  receipt?: boolean,
  hideDiscount?: boolean,
  hideShipping?: boolean,
  hideTax?: boolean,
  hideTotal?: boolean,
  checkout?: boolean,
  handleCheckout: () => void,
  handleRemoveAll: () => void
}

const ModifyCartTotals = ({ checkout, readonly, hideInactive, receipt, hideDiscount, hideShipping, hideTax, hideTotal, handleCheckout, handleRemoveAll }: Props) => {
  
  if (readonly) {
    return (<></>)
  }

  if (receipt) {
    return (
      <>
      </>
    )
  }

  return (
    <div className="cart-total">
    <TotalsPanel hideDiscount={hideDiscount} hideShipping={hideShipping} hideTax={hideTax} hideTotal={hideTotal} hideInactive={hideInactive}/>
    <ModifyCartButtons readonly={readonly || checkout} handleCheckout={handleCheckout} handleRemoveAll={handleRemoveAll}/>
  </div>
)
}

export default ModifyCartTotals