import { FormControl, InputLabel, NativeSelect } from '@mui/material';
import { useContext } from 'react';
import ConfigContext from '../../../contexts/ConfigContext';

type Props = {
  item: IItem,
  variants: IItem[],
  setSelectedVariation?: React.Dispatch<React.SetStateAction<IItem>>
}

const VariantSelector = ({ item, variants, setSelectedVariation }: Props) => {

  if (variants.length === 0) {
    return (
      <>
      </>
    )
  }

  const doOnChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
    if (event.target.value === item.id) {
      if (setSelectedVariation) {
        setSelectedVariation(item);
      }
    }
   for (let i = 0; i < variants.length; i++) {
      const variant = variants[i];
      if (event.target.value === variant.id) {
        if (setSelectedVariation) {
          setSelectedVariation(variant);
        }
      }
    }
  }

  return (
    <FormControl>
      <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={true}>
        Variation
      </InputLabel>
      <NativeSelect
        defaultValue={item.id}
        inputProps={{
          name: 'age',
          id: 'uncontrolled-native',
          onChange: doOnChange
        }}
      >
        <option id={'variant-group'} value={item.id}>Select variation...</option>
        {variants.map((variant, idx) => {
          const stock = Object.keys(variant.inventory).reduce((sum, key) => sum + variant.inventory[key].count, 0);
          return (
            <option id={`variant${idx}`} value={variant.id}>
              {variant.group?.variantName ? variant.group?.variantName : variant.name}
            </option>
          );
        })}
      </NativeSelect>
    </FormControl>
  )
}

export default VariantSelector