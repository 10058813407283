import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { useContext, useEffect, useState } from 'react';
import ShippingClient from '../../api/ShippingClient';
import CartContext from '../../contexts/CartContext';
import DeliveryAddressDialog from '../dialogs/DeliveryAddressDialog';
import Price from './shop/Price';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

type Props = {
}

const ShippingNotice = ({ }: Props) => {
  const [addressDialog, setAddressDialog] = useState<boolean>(false)
  const {cart, setCartState} = useContext(CartContext);
  const [shippingDetails, setShippingDetails] = useState<ShippingDetails|undefined>(undefined)

  const doGetBestShippingEstimate = async() => {
    setShippingDetails(await ShippingClient.doGetBestShippingEstimate(cart));
  }


  useEffect(() => {
    doGetBestShippingEstimate();
  }, []);

  useEffect(() => {
    if (cart.deliveryAddress.postal) {
      doGetBestShippingEstimate();
    }
  }, [cart.deliveryAddress.postal]);

  const getFreeShippingText = () => {
    if (shippingDetails?.freeShippingThreshold && cart.deliveryAddress.postal) {
      return (
        <>
        <span className='inline-text'>Free shipping to {cart.deliveryAddress.postal} on</span>
        <Price price={shippingDetails.freeShippingThreshold}/>
        <span className='inline-text'>purchase</span>
        </>
      )
    }
    return (<>Set postal code to calculate shipping</>);
  }

  const getTooltip = () => {
    return (
      <>
        <div className='clear-bottom'>
          Shipping calculated on checkout before payment.
        </div>
        <div>
          Click to set delivery address and calculate free shipping threshold.
        </div>
      </>
    )
  }

  const colour = shippingDetails?.freeShippingThreshold && cart.deliveryAddress.postal ? "primary" : "secondary";

  return (
    <>
      <DeliveryAddressDialog initialState={addressDialog} onOK={() => setAddressDialog(false)}/>
      <Tooltip title={getTooltip()}>
          <Button className='free-shipping-notice' color={colour} variant="outlined" startIcon={<LocalShippingIcon/>} onClick={() => setAddressDialog(true)}>
            {getFreeShippingText()}
          </Button>
      </Tooltip>
    </>
  )
}

export default ShippingNotice