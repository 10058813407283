import Price from '../shop/Price';

type Props = {
  order: IOrder,
  ignoreShipping?: boolean
}


const InvoiceReviewTotal = ({ order, ignoreShipping }: Props) => {
  const items = order.items;
  const subtotal = items.filter(item => !item.item.name.startsWith("Shipping")).reduce((subtotal, item) => subtotal + (item.count * (item.item.price ? item.item.price : 0)), 0);
  const shipping = items.filter(item => item.item.name.startsWith("Shipping")).reduce((subtotal, item) => subtotal + (item.count * (item.item.price ? item.item.price : 0)), 0);
  const discountedSubtotal = order.discountAmount ? subtotal - order.discountAmount : subtotal;

  const taxes = Math.round((discountedSubtotal + shipping) * 0.15);

  const total = discountedSubtotal + shipping + taxes;

  return (
    <Price price={total}/>
  )
}

export default InvoiceReviewTotal