import React, { useContext, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CartContext from '../../../../contexts/CartContext';
import SquareupPaymentProvider from './provider/SquareupPaymentProvider';
import ConfigContext from '../../../../contexts/ConfigContext';
import PaysafePaymentProvider from './provider/PaysafePaymentProvider';
import EmtPaymentProvider from './provider/EmtPaymentProvider';


type Props = {
  setGetPaymentToken: React.Dispatch<React.SetStateAction<undefined | (() => () => Promise<any>)>>;
  getPaymentToken: any
}

const PaymentDescription = ({getPaymentToken, setGetPaymentToken}: Props) => {
  const {config} = useContext(ConfigContext);
  const {cart, setCartState} = useContext(CartContext);

  const getEmtText = () => {
    return (
      <Card className='payment-description'>
        <CardContent>
          <EmtPaymentProvider setGetPaymentToken={setGetPaymentToken}/>
        </CardContent>
      </Card>
    );
  }

  const getCardText = () => {
    const providers = config.general.payment_providers as Record<string,Record<string, string>>;
    for(let provider of Object.values(providers)) {
      switch (provider.name) {
        case "Square":
          return (
            <Card className='payment-description'>
              <CardContent>
                <SquareupPaymentProvider setGetPaymentToken={setGetPaymentToken}/>
              </CardContent>
            </Card>
          );
        case "Paysafe":
          return (
            <Card className='payment-description'>
              <CardContent>
                <PaysafePaymentProvider setGetPaymentToken={setGetPaymentToken}/>
              </CardContent>
            </Card>
          );
        case "Clover":
          break;
      }
    }

    return (
      <Card className='payment-description'>
        <CardContent>
          Currently unsupported
        </CardContent>
      </Card>
    );
  }

  const getSezzleText = () => {
    const merchantId = 'da9b5ebc-2c66-4bd4-9d16-775f1e1fbf64';
    return (
      <Card className='payment-description'>
        <CardContent>
          Sezzle is not available at this time
        </CardContent>
      </Card>
    );
  }


  switch (cart.paymentMethod) {
    case 'emt':
      return getEmtText();
    case 'card':
      return getCardText();
    case 'sezzle':
      return getSezzleText();
    default:
      return (
        <>
        </>
      )
  }
}

export default PaymentDescription