import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import API from '../../API';

type Props = {
  item?: IItem,
  variant?: IItem,
  categoryId?: string,
}

const BreadCrumbs = ({ item, variant, categoryId }: Props) => {

  const [categoryPath, setCategoryPath] = useState<ICategory[]>([]);

  useEffect(() => {
    const fetchCategoryPath = async () => {
      if (item?.id) {
        try {
          // const allRoots = await getCategories();
          // const roots = allRoots.filter(applyRootFilter);
          // const myCategories = roots.flatMap(category => {
          //   return category.children;
          // });
          const path = await API.getCategoryPathById(item.categoryId);

          if (path) {
            setCategoryPath(path);
          }
        }
        catch (err: any) {
          console.error(err.message);
        }
      }
    }
  
    fetchCategoryPath();
  }, [item])

  useEffect(() => {
    const fetchCategoryPath = async () => {
      if (categoryId) {
        try {
          // const allRoots = await getCategories();
          // const roots = allRoots.filter(applyRootFilter);
          // const myCategories = roots.flatMap(category => {
          //   return category.children;
          // });
          const path = await API.getCategoryPathById(categoryId);
          if (path) {
            setCategoryPath(path);
          }
        }
        catch (err: any) {
          console.error(err.message);
        }
      }
    }
  
    fetchCategoryPath();
  }, [categoryId])


  if (categoryId && categoryPath?.length > 0) {
    const myPath = [...categoryPath];
    const lastCat = myPath.pop();
    return (
      <div className='breadcrumb'>
        &nbsp;&nbsp; <span><Link to={`/category/`}>Categories</Link></span>
        &nbsp;&nbsp; &gt;
        {
          myPath.map(category => {
            const cid = category?.id ? category?.id.replace('#', '') : '';
            return (
            <>
              &nbsp;&nbsp; <span><Link key={category.id} to={`/category/${cid}`}>{category?.shortName}</Link></span>
              &nbsp;&nbsp; &gt;
            </>
          )})
        }
        &nbsp;&nbsp; <span>{lastCat?.shortName}</span>
      </div>
    )
  }

  if (item && variant && item.id !== variant.id) {
    return (
      <div className='breadcrumb'>
        &nbsp;&nbsp; <span><Link to={`/category/`}>Categories</Link></span>
        &nbsp;&nbsp; &gt;
        {
          categoryPath.map(category => {
            const cid = category?.id ? category?.id.replace('#', '') : '';
            return (
            <>
              &nbsp;&nbsp; <span><Link key={category.id} to={`/category/${cid}`}>{category?.shortName}</Link></span>
              &nbsp;&nbsp; &gt;
            </>
          )})
        }
        &nbsp;&nbsp; <span><Link to={`/item/${item.id}`} onClick={() => window.location.reload()}>{item.name}</Link></span>
        &nbsp;&nbsp; &gt;
        &nbsp;&nbsp; <span>{variant.group?.variantName ? variant.group.variantName : variant.name}</span>
      </div>
    )
  }

  if (item) {
    return (
      <div className='breadcrumb'>
        &nbsp;&nbsp; <span><Link to={`/category/`}>Categories</Link></span>
        &nbsp;&nbsp; &gt;
        {
          categoryPath.map(category => {
            const cid = category?.id ? category?.id.replace('#', '') : '';
            return (
            <>
              &nbsp;&nbsp; <span><Link key={category.id} to={`/category/${cid}`}>{category?.shortName}</Link></span>
              &nbsp;&nbsp; &gt;
            </>
          )})
        }
        &nbsp;&nbsp; <span>{item.name}</span>
      </div>
    )
  }

  return (
    <>
    </>
)
}

export default BreadCrumbs