import { useContext } from 'react';
import Obfuscate from 'react-obfuscate';
import ConfigContext from '../../contexts/ConfigContext';

const ContactPage = () => {
  const {config} = useContext(ConfigContext);

  return (
    <div id="legal-main">

      <div>
      <span className='business-name'>{config.general.product_name} {config.general.product_subtext}</span> can be contacted at:
        <table>
          <tr><th>Phone:</th><td><Obfuscate tel="(902) 343-3569" /></td></tr>
          <tr><th>Email:</th><td><Obfuscate email={config.general.email}/></td></tr>
          <tr><th>&nbsp;</th><td></td></tr>
          <tr><th>Street:</th><td>186 Church Street</td></tr>
          <tr><th>PO Box:</th><td>Post Office Box 74</td></tr>
          <tr><th>City/Province:</th><td>Liverpool, Nova Scotia</td></tr>
          <tr><th>Country:</th><td>Canada</td></tr>
          <tr><th>Postal Code:</th><td>B0T 1K0</td></tr>
        </table>

      </div>



      <div></div>
    </div>
  )
}

export default ContactPage


