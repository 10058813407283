import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../API';
import InvoiceReviewPanel from '../widgets/invoice/InvoiceReviewPanel';

const InvoicePage = () => {
  let { orderId } = useParams<{orderId: string}>();
  const [order, setOrder] = useState<IOrder|undefined>();

    // Submit only once
    useEffect(() => {
      const doSubmitOrder = async() => {
        if (orderId) {
          const order = await API.getOrderById(orderId, undefined);
          if (order) {
            // If the order is submitted, clear the cart
            setOrder(order);
          }
        }
      }
  
      doSubmitOrder();
    }, [orderId]);

  if (!order) {
    return (
      <div className='receipt-parent'>
        <div className='receipt-main'>
          Loading order...
        </div>
      </div>
    );
  }

  return (
    <div className='receipt-parent'>
      <div className='receipt-main'>
        <InvoiceReviewPanel order={order as IOrder} />
       </div>
     </div>
    )
}

export default InvoicePage