import { useContext } from 'react'
import ConfigContext from '../../../contexts/ConfigContext';

type Props = {
  name: string,
  showName?: boolean
}

const Brand = ({ name, showName }: Props) => {
  const {config} = useContext(ConfigContext);
  const root = config.general.root_category;

  const imgPath = 'https://thequeensflorist.sirv.com/' + root + '/brands/' + name.toLowerCase() + '.png';

  if (showName) {
    return (
      <div className='brand'>
          <div><img src={imgPath} alt={name}/></div>
          <div>{name}</div>
      </div>
    )
  }

  return (
    <div className='brand'>
      <div><img src={imgPath} alt={name}/></div>
  </div>
)

}

export default Brand
