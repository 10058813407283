import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    handleClose: (success: boolean) => void
  }
  
export default function AlertDialog({ open, setOpen, handleClose }: Props) {

    const myHandleCloseYes = () => {
        setOpen(false);
        handleClose(true);
      };
    
      const myHandleCloseNo = () => {
        setOpen(false);
        handleClose(false);
      };
    
          return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Remove all?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will remove all items from your cart. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={myHandleCloseNo} color="primary">
            No
          </Button>
          <Button onClick={myHandleCloseYes} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
