import React, { useContext } from 'react';
import ItemImage from './ItemImage'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link, useHistory, useLocation } from "react-router-dom";
import CartContext from '../../../contexts/CartContext';
import Price from './Price';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import IfTrue from '../../conditions/IfTrue';
import PriceRange from './PriceRange';
import ItemBanner from './ItemBanner';

type Props = ItemProps & {
}

const Item = ({ item }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [display, setDisplay] = React.useState<undefined | string>('flex');
  const history = useHistory();
  const location = useLocation();

  // Get the cart from the context
  const {cart, setCartState} = useContext(CartContext);
  const [movingItem, setMovingItem] = React.useState<undefined | ICategory >(undefined);

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const hiddenClass = item.hidden ? 'hidden' : '';
  const newCategoryName = movingItem ? movingItem.name : '';


  const isGroup = item?.group?.enabled && !item?.group?.variantName;
  const stockCounter = isGroup ?
    (item.group?.variantSummary?.stock) :
    Object.keys(item.inventory).reduce((sum, key) => sum + item.inventory[key].count, 0);
  
  if (isGroup) {
    const priceHigh = item.group?.variantSummary?.priceHigh;
    const priceLow = item.group?.variantSummary?.priceLow;
    
    return (
      <>
      <Card className={'item-card ' + hiddenClass}>
        <Link className='card-link' to={{pathname: '/item/' + item.id, state: {from: location.pathname}}}>
        <CardContent className='card'>
          <IfTrue condition={item?.discontinued}>
            <div className='discontinued-banner'>Discontinued</div>
          </IfTrue>
          <ItemImage id={item.imageId} height={180} title={item.name} timestamp={item.updatedAt}/>
          <div className={'card-title'} color="textPrimary">
            <div className='brand-name'>{item.brand}</div>
            <div className='item-name'>{item.name}</div>
          </div>
          <div>
            <PriceRange priceLow={priceLow as number} priceHigh={priceHigh as number}/>
          </div>
          <IfTrue condition={item.discontinued}>
            <div className='stock-count space-above'>{stockCounter} left in stock</div>
          </IfTrue>
          <ItemBanner item={item}/>
        </CardContent>
        </Link>
      </Card>
      </>
    )

  }

  return (
    <>
    <Card className={'item-card ' + hiddenClass}>
      <Snackbar open={open} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
        <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="success">
          Added {item.name} to cart.
        </Alert>
      </Snackbar>
      <Link className='card-link' to={{pathname: '/item/' + item.id, state: {from: location.pathname}}}>
      <CardContent className='card'>
        <IfTrue condition={item?.discontinued}>
          <div className='discontinued-banner'>Discontinued</div>
        </IfTrue>
          <ItemImage id={item.imageId} height={180} title={item.name} timestamp={item.updatedAt}/>
          <div className={'card-title'} color="textPrimary">
            <div className='brand-name'>{item.brand}</div>
            <div className='item-name'>{item.name}</div>
          </div>
          <div>
            <Price price={item.price}/>
          </div>
          <IfTrue condition={item.discontinued}>
            <div className='stock-count space-above'>{stockCounter} left in stock</div>
          </IfTrue>

        <ItemBanner item={item}/>
      </CardContent>
      </Link>
    </Card>
    </>
  )
}

export default Item