import React, { useContext, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import ConfigContext from '../../contexts/ConfigContext';


/**
 * Gather whatever identity information we can and make it
 * available.
 */
const SiteDisabled : React.FunctionComponent<{}> = props  => {
  const {config} = useContext(ConfigContext);
  const [diabledCheck, setDisabledCheck] = React.useState<boolean>(false);

  const disabled = config.general.site_disabled;

    if ((disabled && !diabledCheck)
        // || !cart.customerAddress.tosCheck
        // || !cart.customerAddress.privacyCheck
      ) {
        return (
            <div id='id-main' className='flex-div disabled-validation-parent'>
                <Card className='disabled-validation'>
                    <CardContent>
                        <form noValidate autoComplete="off">
                            <div className='title'>Welcome to <span className='business-name'>{config.general.product_name}</span>,</div>
                            {/* <div className='subtitle'>Due to local laws, age verification is required.</div> */}
                            <div className='text'>
                              Ordering from the site is temporarily disabled while we perform yearly inventory and
                              restock. Please call <b>(902) 343-3569</b> to order by phone with one of our
                              super helpful staff. Sorry for the inconvenience.
                            </div>
                            <Button variant="contained" color="primary" onClick={() => {setDisabledCheck(true);}}>
                                Its not OK but I understand.
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </div>
        )
    }

    return (
        <>
            {props.children}
        </>
    )

}

export default SiteDisabled
