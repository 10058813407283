import React, { useContext, useEffect } from 'react';
import Step1 from '../widgets/checkout/steps/Step1';
import Step2 from '../widgets/checkout/steps/Step2';
import Step3 from '../widgets/checkout/steps/Step3';
import Step4 from '../widgets/checkout/steps/Step4';
import CartContext from '../../contexts/CartContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IfFalse from '../conditions/IfFalse';
import IfTrue from '../conditions/IfTrue';
import ConfigContext from '../../contexts/ConfigContext';
import { Button, Step, StepLabel, Stepper } from '@mui/material';
import NextButton from '../widgets/checkout/steps/NextButton';

function getSteps() {
  return ['Review Order', 'Contact Information', 'Shipping Information', 'Payment'];
}

const CheckoutPage = () => {
  const {cart, setCartState} = useContext(CartContext);
  const [preNextAction, setPreNextAction] = React.useState<() => () => Promise<boolean>>(() => async () => {return true});
  const steps = getSteps();
  const {config} = useContext(ConfigContext);

  const [nextName, setNextName] = React.useState('Next');
  const [step, setStep] = React.useState(0);

  const setActiveStep = (step: number) => {
    if (step < 0) {
      step = 0;
    }
    setStep(step);
  }

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (<Step1 setNextName={setNextName} setPreNextAction={setPreNextAction}/>);
      case 1:
        return (<Step2 setNextName={setNextName} setPreNextAction={setPreNextAction}/>);
      case 2:
        return (<Step3 setNextName={setNextName} setPreNextAction={setPreNextAction}/>);
      case 3:
        return (<Step4 setNextName={setNextName} setPreNextAction={setPreNextAction} setActiveStep={setActiveStep}/>);
      default:
        return 'Unknown stepIndex';
    }
  }  

  const handleNext = () => {
    const doNext = async () => {
      let success: boolean = false;
      const getSuccess = await preNextAction();
      success = getSuccess as any;

      console.log("  PRE ACTION SUCCESS: " + success);
      if (success) {
        console.log("  SETTING ACTIVE STEP: " + (step + 1));
        setActiveStep(step + 1);
      }
    }

    doNext();
  };

  const handleBack = () => {
    setActiveStep(step - 1);
  };

  const disabled = config.general.site_disabled;

  return (
    <>
    <IfTrue condition={disabled}>
      <div className="cart-parent">
        <div className="cart-main">
          <Card>
            <CardContent>
              Ordering from the site is temporarily disabled while we perform yearly inventory and
              restock. Please call <b>(902) 343-3569</b> to order by phone with one of our
              super helpful staff. Sorry for the inconvenience.
            </CardContent>
          </Card>
        </div>
      </div>
      </IfTrue>
      <IfFalse condition={disabled}>
        <div className='checkout-progress-parent'>
          <Stepper activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {step === steps.length ? (
              <div>
              </div>
            ) : (
              <div>
                <div>{getStepContent(step)}</div>
                <div className='step-buttons'>
                  <Button
                    disabled={step === 0}
                    onClick={handleBack}
                    variant="contained" color="secondary">
                    Back
                  </Button>
                  <NextButton steps={steps} nextName={nextName} activeStep={step} handleNext={handleNext}/>
                </div>
              </div>
            )}
          </div>
        </div>
      </IfFalse>
    </>
  )
}

export default CheckoutPage