import { useContext } from 'react';
import ConfigContext from '../../../contexts/ConfigContext';

type Props = {
  id?: string
  title: string
  height?: number
  width?: number
  timestamp?: string | Date
  className?: string
  notLazy?: boolean
}

const ItemImage = ({ id, title, timestamp, width, height, className, notLazy }: Props) => {
  const {config} = useContext(ConfigContext);
  const root = config.general.root_category;
  const args = (width ? `w=${width}px` : '') + (!width && height ? `h=${height}px` : '');

  const dataSrc = notLazy ? undefined : `https://thequeensflorist.sirv.com/${root}/${id}.jpg`;
  const src = notLazy ? `https://thequeensflorist.sirv.com/${root}/${id}.jpg` : undefined;

  if (className) {
    if (id) {
      return (
        <div className='image'>
            <img
              className={className + ' Sirv image-fit'}
              style={{maxWidth: `${width}px`, maxHeight: `${height}px`,width: `${width}px`, height: `${height}px`}}
              src={src}
              data-src={dataSrc}
              alt={title}
              title={title}/>
        </div>
      )
    }
    else {
      return (
        <div className='image'>
            {title}
        </div>
      )
    }
  }

  if (id) {
    return (
        <div className='image'>
          <img
            className='Sirv image-fit'
            style={{maxWidth: `${width}px`, maxHeight: `${height}px`,width: `${width}px`, height: `${height}px`}}
            src={src}
            data-src={dataSrc}
            alt={title}
            title={title}/>
        </div>
    )
  }
  else {
    return (
        <div className='image'>
          {title}
        </div>
    )
  }
}

export default ItemImage