import React from 'react'
import Price from './Price'

type Props = {
  variations: IItem[]
}

const PriceSummary = ({ variations }: Props) => {
  if (variations.length === 0) {
    return (
      <>$<span className="price"><span className="dollars">X</span><span className="cents">XX</span></span></>
    )
  }

  if (variations.length === 1) {
    return (
      <Price variation={variations[0]}/>
    )
  }

  const sorted = variations.sort((a, b) => (a?.price ? a.price : 0) - (b?.price ? b.price : 0));

  if (sorted[0].price === sorted[sorted.length - 1].price) {
    return (
      <Price variation={sorted[0]}/>
    )
  }

  return (
    <><Price variation={sorted[0]}/> - <Price variation={sorted[sorted.length - 1]}/></>
  )

}

export default PriceSummary
