import React, { useContext, useEffect, useState } from 'react';
import Price from '../shop/Price';
import CartContext from '../../../contexts/CartContext';
import API from '../../../API';

type Props = {
  items: ICartItem[],
  method?: string
}


const Discount = ({ items, method }: Props) => { 
  // Get the cart from the context
  const subtotal = items.reduce((subtotal, item) => subtotal + (item.count * (item.item.price ? item.item.price : 0)), 0);
  const {cart, setCartState} = useContext(CartContext);
  const [discount, setDiscount] = useState<IDiscount|undefined>();

  useEffect(() => {
    const doGetDiscount = async() => {
      if (subtotal && cart.discountCode) {
        const discountDetails = await API.getDiscount(cart.discountCode);
        console.log("DISCOUNT: " + JSON.stringify(discountDetails));
        setDiscount(discountDetails);
      }
    }

    doGetDiscount();
  }, [subtotal, cart.discountCode]);

  const discountAmount =
  discount?.discountAmount ?
    discount.discountAmount :
    Math.round(subtotal * (
      discount?.discountPercent ?
      discount.discountPercent :
      0));

  // Other components want to use this value
  if (discountAmount !== cart.discountAmount) {
    setCartState({
      ...cart,
      discountAmount: discountAmount
    })
  }


  if (!discountAmount) {
    return (
      <>
      </>
    )
  }

  return (
    <>
        <div><Price price={discountAmount} showZero={true}/></div>
    </>
  )
}

export default Discount