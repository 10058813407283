import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IfTrue from '../conditions/IfTrue';
import DeliveryAddress from '../widgets/checkout/delivery/DeliveryAddress';

type Props = {
    initialState: boolean,
    onOK: () => void
}


export default function DeliveryAddressDialog({ initialState, onOK }: Props) {
    const [open, setOpen] = React.useState(initialState);
    const [value, setValue] = React.useState('');

  if (initialState !== open) {
    setOpen(initialState);
    setValue('');
  }

  const handleOK = () => {
    setOpen(false);
    onOK();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleOK}>
        <DialogTitle>Set shipping destination</DialogTitle>
        <DialogContent>
        <DialogContentText className="clear-bottom">
              Set the shipping destination postal code to calculate free shipping threshold and
              pre-calculate shipping before checkout.
          </DialogContentText>
          <DialogContentText className="clear-bottom">
              Shipping is always calculated before payment. The closer your purchase price is
              to the "free" threshold, the lower the shipping.
          </DialogContentText>
          <DialogContentText className="clear-bottom">
            "Special order" items may not qualify for free shipping.
          </DialogContentText>
          <DeliveryAddress ignoreAddress={true} ignoreShippingMethod={true}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOK}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}