import React, { useContext, useEffect, useState } from 'react';
import { FormControl, FormLabel, SelectChangeEvent, TextField } from '@mui/material';
import CartContext from '../../../../contexts/CartContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ProvinceSelector from './ProvinceSelector';
import IfFalse from '../../../conditions/IfFalse';

type Props = {
  ignoreShippingMethod?: boolean,
  ignoreAddress?: boolean
}

const DeliveryAddress = ({ ignoreAddress, ignoreShippingMethod }: Props) => {

  // Get the cart from the context
  const {cart, setCartState} = useContext(CartContext);

  const [override, setOverride] = useState<DeliveryAddress>(cart.deliveryAddress);

  // Pre-set the name, but only do this once
  useEffect(() => {
    const newCartState = {
      ...cart,
      deliveryAddress: {
        ...cart.deliveryAddress,
        firstName: cart.deliveryAddress.firstName ? cart.deliveryAddress.firstName : cart.customerAddress.firstName,
        lastName: cart.deliveryAddress.lastName ? cart.deliveryAddress.lastName : cart.customerAddress.lastName
      }};
    setCartState(newCartState);
    
    setOverride(newCartState.deliveryAddress);
  }, []);
  
  const updateDeliveryAddressField = (name: string, value: string) => {
    setOverride({
      ...override,
        [name]: value
    });
  }

  const onFirstNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDeliveryAddressField('firstName', event.target.value);
  }

  const onLastNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDeliveryAddressField('lastName', event.target.value);
  }

  const onStreetChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDeliveryAddressField('street', event.target.value);
  }

  const onAdditionalChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDeliveryAddressField('additional', event.target.value);
  }

  const onCityChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDeliveryAddressField('city', event.target.value as string);
  }

  const onProvinceChanged = (value: string) => {
    updateDeliveryAddressField('province', value as string);
    // We know when postal code is ready
    setCartState({
      ...cart,
      deliveryAddress: {
        ...override,
        province: value
    }});
  };

  const onPostalChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    updateDeliveryAddressField('postal', value.toUpperCase());
    if (value.replaceAll(" ", "").length === 6) {
      override.postal = value;
      // We know when postal code is ready
      setCartState({
        ...cart,
        deliveryAddress: override
      });
    }
  }

  /**
   * When we have a blur, sychronize the override with the cart
   */
  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setCartState({
      ...cart,
      deliveryAddress: override
    });
  }
  

  if (!ignoreShippingMethod && cart.shippingMethod === 'PICKUP') {
    return (
      <>
      </>
    );
  }

  return (
    <Card>
      <CardContent>
        <FormControl component="fieldset">
          <FormLabel component="legend" className='checkout-form-legend'>Delivery details</FormLabel>
            <div className='checkout-form-fields'>
              <IfFalse condition={ignoreShippingMethod}>
                <TextField required id="firstName" label="First Name" value={override.firstName} onChange={onFirstNameChanged} onBlur={onBlur}/>
                <TextField required id="lastName" label="Last Name" value={override.lastName} onChange={onLastNameChanged}/>
              </IfFalse>
              <IfFalse condition={ignoreAddress}>
                <TextField required id="street" label="Street Address" value={override.street} onChange={onStreetChanged} onBlur={onBlur}/>
                {/* <TextField id="additional" label="Additional" value={override.additional} onChange={onAdditionalChanged} onBlur={onBlur}/> */}
                <TextField required id="city" label="City" value={override.city} onChange={onCityChanged} onBlur={onBlur}/>
                <ProvinceSelector
                  province={override.province}
                  onChange={onProvinceChanged}
                  />
              </IfFalse>
              <FormControl>
                <TextField
                  label="Postal Code"
                  value={override.postal}
                  onChange={onPostalChanged}
                  onBlur={onBlur}
                  name="postal"
                  id="postal"
                />
              </FormControl>
            </div>
          </FormControl>
      </CardContent>
    </Card>
  )
}

export default DeliveryAddress;
