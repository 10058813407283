import { useContext, useEffect, useRef } from 'react';
import CartContext from '../../../../../contexts/CartContext';

type Props = {
  setGetPaymentToken: React.Dispatch<React.SetStateAction<undefined | (() => () => Promise<any>)>>;
}

const PaysafePaymentProvider = ({setGetPaymentToken}: Props) => {

  const {cart, setCartState} = useContext(CartContext);


  const cartRef: any = useRef();
  cartRef.current = cart;

  const nonPaymentCheckout = async() : Promise<any> => {
    return {
      method: "emt"
    }
  }

  useEffect(() => {
    setGetPaymentToken(() => nonPaymentCheckout);
  }, []);

  return (
    <>
      <div>
        Send Email Transfer (EMT) to billing@atlanticgrowshop.ca; Auto-deposit has been enabled, a passcode is not required.
      </div>
      <div>
        Items will be held for 5 business days awaiting payment. Order will ship after payment has been received.
      </div>
    </>
  );
}

export default PaysafePaymentProvider;