import React, { useContext } from 'react';
import CartContext from '../../../contexts/CartContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IfTrue from '../../conditions/IfTrue';

const ShippingPanel = () => {
  // Get the cart from the context
  const {cart, setCartState} = useContext(CartContext);

  return (
    <>
      <div>
        Shipping
      </div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{cart.deliveryAddress.firstName} {cart.deliveryAddress.lastName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={3}>Address</TableCell>
              <TableCell>{cart.deliveryAddress.street} {cart.deliveryAddress.additional}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{cart.deliveryAddress.city}, {cart.deliveryAddress.province}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{cart.deliveryAddress.postal}</TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell>Distance</TableCell>
              <TableCell>{cart.distance}km</TableCell>
            </TableRow> */}
            <IfTrue condition={!!cart.deliveryDate}>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>{cart.deliveryDate?.toString()}</TableCell>
              </TableRow>
            </IfTrue>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ShippingPanel