import Alert from '@mui/material/Alert';

type Props = {
  notes?: string
}

const ItemNotes = ({ notes }: Props) => {

  if (!notes) {
    return (
      <>
      </>
    )
  }

  return (
    <Alert severity="info">{notes}</Alert>
  )
}

export default ItemNotes