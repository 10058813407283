import React, { useContext, useEffect } from 'react';
import CartContext from '../../../../contexts/CartContext';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CartUtils from '../../../../utils/CartUtils';
import ConfigContext from '../../../../contexts/ConfigContext';
import IfFalse from '../../../conditions/IfFalse';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        minWidth: '25ch',
      },
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
    },
  }),
);

type Props = {
  setNextName: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentOptions = ({setNextName}: Props) => {
  const {config} = useContext(ConfigContext);
  const {cart, setCartState} = useContext(CartContext);
  const classes = useStyles();

  const updateCartField = (name: string, value: any) => {
    setCartState({
      ...cart,
      [name]: value
      });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCartField('paymentMethod', (event.target as HTMLInputElement).value);
  };

  useEffect(() => {
      const dostuff = async () => {
      switch (cart.paymentMethod) {
        case "emt": setNextName("Submit Order"); break;
        default:
          const utils = new CartUtils(cart);
          const total = await utils.getTotal();
          if (total !== undefined) {
            const dollars = Math.trunc(total / 100);
            const cents = total - (dollars * 100);
            setNextName("Pay $" + dollars + "." + cents);
          }
          else {
            setNextName("Next");
          }
          break;
      }
    }

    dostuff();
  }, [cart, setNextName]);

  let disableCard: boolean = true;
  let disableCardAdmin: boolean = true;

  let hideEmt = false;
  let hideSezzle = true;

  const providers = config.general.payment_providers as Record<string,Record<string, string>>;
  for(let provider of Object.values(providers)) {
    switch (provider.name) {
      case "Square":
      case "Clover":
      case "Paysafe":
        disableCard = !provider.prod;
        disableCardAdmin = !provider.dev;
        break;
      case "EMT":
        hideEmt = false;
        break;
      case "Sezzle":
        hideSezzle = false;
        break;
      }
  }

  return (
    <Card className={classes.root + ' payment-options'}>
      <CardContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Payment Options</FormLabel>
          <RadioGroup aria-label="payment" name="payment" value={cart.paymentMethod} onChange={handleChange}>
            <IfFalse condition={hideEmt}>
              <FormControlLabel value="emt" control={<Radio />} label="Email Money Transfer (e-transfer, EMT)" disabled={false}/>
            </IfFalse>
            <FormControlLabel value="card" control={<Radio />} label="Credit Card" disabled={false}/>
            <IfFalse condition={hideSezzle}>
              <FormControlLabel value="sezzle" control={<Radio />} label="Sezzle (Coming soon)" disabled={true}/>
            </IfFalse>
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  )
}

export default PaymentOptions