import React, { useContext, useEffect, useState } from 'react';
import Price from '../shop/Price';
import CartContext from '../../../contexts/CartContext';
import ShippingClient from '../../../api/ShippingClient';

type Props = {
  items: ICartItem[],
  ignoreShipping?: boolean
}


const Taxes = ({ items, ignoreShipping }: Props) => {
  const subtotal = items.reduce((subtotal, item) => subtotal + (item.count * (item.item.price ? item.item.price : 0)), 0);
  const [shipping, setShipping] = useState<number|undefined>(undefined);
  const {cart, setCartState} = useContext(CartContext);
  const discountedSubtotal = cart.discountAmount ? subtotal - cart.discountAmount : subtotal;

  const doGetShipping = async() => {
    if (cart.shippingMethod) {
      try {
        const shippingDetails = await ShippingClient.getShipping(cart.shippingMethod, cart, discountedSubtotal);
        if (shippingDetails !== undefined) {
          if (discountedSubtotal) {
            if (shippingDetails.freeShippingThreshold && discountedSubtotal >= shippingDetails.freeShippingThreshold) {
              setShipping(0);
              return;
            }
            if (shippingDetails.price >= 0) {
              setShipping(shippingDetails.price);
            }
            else {
              setShipping(undefined);
            }
            return;
          }
        }
      }
      catch (e: any) {
        console.error(e);
      }
    }
    setShipping(undefined);
    return;
  }

  useEffect(() => {
    if (!ignoreShipping) {
      doGetShipping();
    }
  }, []);


  useEffect(() => {
    if (!ignoreShipping) {
      doGetShipping();
    }
  }, [cart.shippingMethod, cart.deliveryAddress]);

  const taxes = shipping !== undefined ? Math.round((discountedSubtotal + shipping) * 0.15) : undefined;

  if (!taxes) {
    return (
      <></>
    )  
  }
  
  return (
    <Price price={taxes}/>
  )
}

export default Taxes