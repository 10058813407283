import React, { useContext } from 'react';
import * as validator from 'email-validator';
import Button from '@mui/material/Button';
import CartContext from '../../../../contexts/CartContext';
import ConfigContext from '../../../../contexts/ConfigContext';

type Props = {
  steps: string[],
  activeStep: number,
  handleNext: () => void,
  nextName?: string
}

const STEP1 = 0;
const STEP2 = 1;
const STEP3 = 2;
const STEP4 = 3;


const NextButton = ({ steps, activeStep, handleNext, nextName }: Props) => {
  const {cart, setCartState} = useContext(CartContext);
  const {config} = useContext(ConfigContext);

  let enabled = false;

  function getMothersDay() {
    // FIXME: This should not be hard coded
    const d = new Date();
    d.setFullYear(2022);
    d.setMonth(4);
    d.setDate(8);
    return new Date(d);
  }

  const mothersDay = getMothersDay();

  const isValidDeliveryDate = (dateValue?: Date): boolean => {
    if (!dateValue) {
      return false;
    }
    
    const now = new Date();
    const date = new Date(dateValue);

    // To calculate the time difference of two dates
    var difference_In_Time = date.getTime() - now.getTime();
    if (difference_In_Time < 0) {
      return false;
    }
    
    if (now.getDate() === date.getDate() &&
        now.getMonth() === date.getMonth() &&
        now.getFullYear() === date.getFullYear()) {
          return false;
    }

    if (date.getDay() === 0 && !(
      date.getDate() === mothersDay.getDate() &&
      date.getMonth() === mothersDay.getMonth()
    )) {
      return false;
    }
    if (date.getMonth() === 4 && date.getDate() === 7) {
      return false;
    }
    return true;
  }

  switch (activeStep) {
    case STEP1: {
      const items: ICartItem[] = Object.values(cart.items);
      const specialOrder = items.filter((item) => !!item.item.special).length > 0;
      if (specialOrder) {
        enabled = false;
      }
      enabled = true;
      break;
    }
    case STEP2: {
      // Find a reason to enable the next button
      if (cart.customerAddress.firstName?.trim() &&
          cart.customerAddress.lastName?.trim() &&
          cart.customerAddress.phone?.trim() && // 10 digits plus brackets, hyphen, and space
          // FIXME: Somehow switch to https://www.npmjs.com/package/deep-email-validator in the future to make this much more robust
          cart.customerAddress.email &&
          validator.validate(cart.customerAddress.email?.trim())
        ) {
          enabled = true;
        }
      break;
    }
    case STEP3: {
      // Find a reason to enable the next button
      if (cart.shippingMethod?.trim()) {
        switch (cart.shippingMethod.trim()) {
          case "PICKUP":
              enabled = true;
            break;
          default:
            if (cart.shippingMethod?.trim() &&
              cart.deliveryAddress.firstName?.trim() &&
              cart.deliveryAddress.lastName?.trim() &&
              cart.deliveryAddress.street?.trim() &&
              cart.deliveryAddress.city?.trim() &&
              cart.deliveryAddress.province?.trim() &&
              cart.deliveryAddress.postal?.replaceAll(" ", "").length === 6
            ) {
                enabled = true;
            }

            break;
        }
      }
      break;
    }
    case STEP4: {
      // Find a reason to enable the next button
      if (cart.paymentMethod?.trim()) {
          switch(cart.paymentMethod) {
            case "emt": enabled = true; break;
            case "sezzle": enabled = true; break;
            case "card":
              if (
                cart.cardholderAddress?.firstName &&
                cart.cardholderAddress?.lastName &&
                cart.cardholderAddress?.postal &&
                cart.cardholderAddress?.country)
                {
                  enabled = true;
                }
              break;
          }
      }
      break;
    }
    default:
      enabled = true;
      break;
  }

  return (
    <Button variant="contained" color="primary" onClick={handleNext} disabled={!enabled}>
      {nextName ? nextName : 'Next'}
    </Button>
  )
}

export default NextButton