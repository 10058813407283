import { useContext, useEffect, useRef, useState } from 'react';
import ConfigContext from '../../../../../contexts/ConfigContext';
import CartContext from '../../../../../contexts/CartContext';
import CartUtils from '../../../../../utils/CartUtils';
import { FormControl, FormLabel, SelectChangeEvent, TextField } from '@mui/material';
import CountrySelect from '../../../inputs/CountrySelect';

declare global {
  interface Window {
    paysafe:any;
  }
}

const PAYMENT_PROCESSOR_NAME = 'paysafe';
const MERRCO_API_ID = 'paysafeapi';

const loadPaysafeApi = (callback: any) => {
  const existingScript = document.getElementById(MERRCO_API_ID);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://hosted.paysafe.com/checkout/v1/latest/paysafe.checkout.min.js';
    script.id = MERRCO_API_ID;
    document.body.appendChild(script);
    script.onload = () => { 
      if (callback) callback();
    };
  }
  if (existingScript && callback) {
    callback();
  }
};

type Props = {
  setGetPaymentToken: React.Dispatch<React.SetStateAction<undefined | (() => () => Promise<any>)>>;
}

const PaysafePaymentProvider = ({setGetPaymentToken}: Props) => {
  const {config} = useContext(ConfigContext);
  const COMPANY = config.general.product_name;

  const [paysafeLoaded, setPaysafeLoaded] = useState(false);

  const {cart, setCartState} = useContext(CartContext);
  const utils = new CartUtils(cart);


  const cartRef: any = useRef();
  cartRef.current = cart;

  /**
   * Load the merrco API
   * 
   * https://developer.paysafe.com/en/sdks/paysafe-checkout/overview/#Includin
   */
  useEffect(() => {
    loadPaysafeApi(() => {
      setPaysafeLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (paysafeLoaded) {
      setGetPaymentToken(() => checkout);
    }
  }, [paysafeLoaded]);

  // Pre-set the address, but only do this once
  useEffect(() => {
    const cloneAddress = (
      cart.customerAddress?.firstName === cart.deliveryAddress?.firstName &&
      cart.customerAddress?.lastName === cart.deliveryAddress?.lastName
    );
    
    const street = cloneAddress ? cart.deliveryAddress.street : '';
    const additional = cloneAddress ? cart.deliveryAddress.additional : '';
    const city = cloneAddress ? cart.deliveryAddress.city : '';
    const province = cloneAddress ? cart.deliveryAddress.province : '';
    const postal = cloneAddress ? cart.deliveryAddress.postal : '';

    const newCartState = {
      ...cart,
      cardholderAddress: {
        firstName: cart.cardholderAddress?.firstName ? cart.cardholderAddress.firstName : cart.customerAddress.firstName,
        lastName: cart.cardholderAddress?.lastName ? cart.cardholderAddress.lastName : cart.customerAddress.lastName,
        street: cart.cardholderAddress?.street ? cart.cardholderAddress.street : street,
        additional: cart.cardholderAddress?.additional ? cart.cardholderAddress.additional : additional,
        city: cart.cardholderAddress?.city ? cart.cardholderAddress.city : city,
        province: cart.cardholderAddress?.province ? cart.cardholderAddress.province : province,
        country: cart.cardholderAddress?.country,
        postal: cart.cardholderAddress?.postal ? cart.cardholderAddress.postal : postal
      }
    };
    setCartState(newCartState);
      }, []);

  const updateCardholderAddressField = (name: string, value: string) => {
    setCartState({
      ...cart,
      cardholderAddress: {
        ...cart.cardholderAddress,
        [name]: value
      }
    });
  }

  const onFirstNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCardholderAddressField('firstName', event.target.value);
  }

  const onLastNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCardholderAddressField('lastName', event.target.value);
  }

  // const onStreetChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   updateCardholderAddressField('street', event.target.value);
  // }

  // const onAdditionalChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   updateCardholderAddressField('additional', event.target.value);
  // }

  // const onCityChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   updateCardholderAddressField('city', event.target.value as string);
  // }

  const onCountryChanged = (code: string) => {
    console.log("SET field: " + code);
    updateCardholderAddressField('country', code as string);
  }

  // const onProvinceChanged = (event: SelectChangeEvent<string|undefined>) => {
  //   updateCardholderAddressField('province', event.target.value as string);
  //   // We know when postal code is ready
  //   setCartState({
  //     ...cart,
  //     cardholderAddress: {
  //       ...override,
  //       province: (event.target.value as string)
  //   }});
  // };

  const onPostalChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    updateCardholderAddressField('postal', value.toUpperCase());
    // Maybe someday we want to check if this value is right for certain countries, but for not lets just assume it is correct
    // if (value.replace(/[ _]/g, '').length === 6) {
    //   // We know when postal code is ready
    updateCardholderAddressField('postal', value.toUpperCase());
    // }
  }

  const checkout = async() : Promise<any> => {
    const test = (utils.getEnvironment() === "TEST");
    const USERNAME = test ? config.paysafe.dev.username : config.paysafe.prod.username;
    const PASSWORD = test ? config.paysafe.dev.password : config.paysafe.prod.password;
    const CREDENTIALS = btoa(`${USERNAME}:${PASSWORD}`);
    const PAYSAFE_ENVIRONMENT = test ? config.paysafe.dev.environment : config.paysafe.prod.environment;
    const ROOT = config.general.root_category;

    const total = await utils.getTotal();
    const cart = cartRef.current;

    return new Promise((resolve: any, reject: any) => {
      window.paysafe.checkout.setup(CREDENTIALS, {
        holderName: `${cart.cardholderAddress.firstName} ${cart.cardholderAddress.lastName}`,
        billingAddress: {
          country: cart.cardholderAddress.country,
          zip: cart.cardholderAddress.postal
        },
        amount: total,
        currency: "CAD",
        environment: PAYSAFE_ENVIRONMENT,
        companyName: test ? `${COMPANY} TEST` : COMPANY,
        imageUrl: `https://thequeensflorist.sirv.com/${ROOT}/logos/icon-small.png`
      }, function(instance: any, error: any, result: any) {
        if (error) {
          // error handling
          reject(error);
          console.error("ERROR! " + error);
          throw(error);
        }
        
        if (result?.token) {
          const results = {
            provider: PAYMENT_PROCESSOR_NAME,
            location: PAYMENT_PROCESSOR_NAME,
            method: result.paymentMethod,
            token: result.token,
            environment: PAYSAFE_ENVIRONMENT,
            timestamp: new Date(),
            callback: (success: boolean) => {
              if (success) {
                instance.close();
              }
              else {
                setCartState({
                  ...cart,
                  lastError: 'Payment was declined. Try again with the same or another payment method.'
                })
                instance.close();
                reject('Payment was declined. Try again with the same or another payment method.');
              }
            }
          };

          resolve(results);
          return results;
        }       
      },
      function(stage: any) {
        if (stage === "BeforePayment") {
          setCartState({
            ...cart,
            lastError: 'Request cancelled by user.'
          })
          reject('Request cancelled by user.');
        } else if (stage === "DuringPayment") {
          setCartState({
            ...cart,
            lastError: 'Request cancelled by user before completion of payment.'
          })
          reject('Request cancelled by user before completion of payment.');
        } else if (stage === "AfterPayment") {
          // Closed either via instance.close method or by the user from the success screen
        }
      });
    });
  }
  
  if (paysafeLoaded) {
    return (
      <>
        <FormControl component="fieldset">
          <FormLabel component="legend" className='checkout-form-legend'>Cardholder details</FormLabel>

          <div>
            Card payments will be performed securely through <a href="https://www.paysafe.com/ca-en/" target="_blank" rel="noreferrer">Paysafe</a>
          </div>
          <div className='checkout-form-fields'>
              <TextField required id="firstName" label="First Name" value={cart.cardholderAddress.firstName} defaultValue="" onChange={onFirstNameChanged}/>
              <TextField required id="lastName" label="Last Name" value={cart.cardholderAddress.lastName} defaultValue="" onChange={onLastNameChanged}/>
              {/* <TextField required id="street" label="Street Address" value={override.street} defaultValue="" onChange={onStreetChanged}/> */}
              {/* <TextField id="additional" label="Additional" value={override.additional} defaultValue="" onChange={onAdditionalChanged}/> */}
              {/* <TextField required id="city" label="City" value={override.city} defaultValue="" onChange={onCityChanged}/> */}
              <CountrySelect onChange={onCountryChanged}/>
            {/* <ProvinceSelector
              province={override.province}
              onChange={onProvinceChanged}
              /> */}
              <FormControl>
                <TextField
                  label="Postal/Zip Code"
                  value={cart.cardholderAddress.postal}
                  onChange={onPostalChanged}
                  name="postal"
                  id="postal"
                  // inputComponent={TextMaskCustom as any}
                />
              </FormControl>
          </div>
        </FormControl>
      </>
    );
  }

  return (
    <>
    Contacting payment processor...
    </>
  );

}

export default PaysafePaymentProvider;