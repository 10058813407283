import React, { useContext } from 'react';
import CartContext from '../../../../contexts/CartContext';
import TextField from '@mui/material/TextField';
import { Card, CardContent } from '@mui/material';
import ConfigContext from '../../../../contexts/ConfigContext';
import IfTrue from '../../../conditions/IfTrue';

const DeliveryNotes = () => {
  const {cart, setCartState} = useContext(CartContext);
  const {config} = useContext(ConfigContext);

  const handleEditNotes = (event: React.FocusEvent<HTMLInputElement>) => {
    setCartState({
      ...cart,
      notes: event.target.value
    });
  }

  const handleEditCard = (event: React.FocusEvent<HTMLInputElement>) => {
    setCartState({
      ...cart,
      card: event.target.value
    });
  }

  const sample_recipient = cart.deliveryAddress.firstName ? cart.deliveryAddress.firstName : "<recipient>";

  return (
    <>
      <Card className='delivery-notes'>
        <CardContent>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Delivery Notes"
            multiline
            rows={2}
            defaultValue={cart.notes}
            onBlur={handleEditNotes}
            placeholder={"Requests for delivery specifics. We will do our best to accomodate your request."}
            />
        </CardContent>
      </Card>

      <IfTrue condition={config.general.gift_card}>
        <Card className='delivery-notes'>
          <CardContent>
            <TextField
              InputLabelProps={{ shrink: true }}
              label="What would you like the enclosed gift card to say?"
              multiline
              rows={2}
              placeholder={"Dear " + sample_recipient + "..."}
              defaultValue={cart.card}
              onBlur={handleEditCard}
              />
          </CardContent>
        </Card>
      </IfTrue>
    </>
  )
}

export default DeliveryNotes