import Price from '../shop/Price';

type Props = {
  items: ICartItem[]
}


const Subtotal = ({ items }: Props) => {
  const subtotal = items.reduce((subtotal, item) => subtotal + (item.count * (item.item.price ? item.item.price : 0)), 0);

  return (
    <Price price={subtotal}/>
  )
}

export default Subtotal