import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect } from "react";
import { useContext } from "react";
import ConfigContext from "../../contexts/ConfigContext";
import IfAuthorized from "../conditions/IfAuthorized";

const MemberPage = () => {
  const {config, setConfig} = useContext(ConfigContext);

  const [showHidden, setShowHidden] = React.useState(config?.user?.showHidden ? true : false);

  useEffect(() => {
    setShowHidden(config?.user?.showHidden ? true : false);
  }, [config]);


  // const id_token = authentication.getIdToken();
  // const access_token = authentication.getAccessToken();
  // const userName = JSON.stringify(authentication.getUserName());

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("setShowHidden: " + event.target.checked);
    setShowHidden(event.target.checked);
    setConfig({
      ...config,
      user: {showHidden: event.target.checked}
    });
  };

  console.log("ShowHidden: " + showHidden);

  return (
    <>
    <AuthenticatedTemplate>
    <div className='main'>
      Welcome to your member page.
      {/* Welcome to your member page, {id_token.given_name}
      <div>
        Access token: {access_token}
      </div> */}
    </div>

    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
    <div className='main'>
      Nothing to see here.
      {/* Welcome to your member page, {id_token.given_name}
      <div>
        Access token: {access_token}
      </div> */}
    </div>
    </UnauthenticatedTemplate>
    </>
  )
}

export default MemberPage