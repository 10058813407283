import ModifyCartPanel from '../widgets/cart/ModifyCartPanel';

const ModifyCartPage = () => {

  return (
    <>
    <ModifyCartPanel hideDiscount={true} hideTax={true} hideTotal={true}/>
    </>
  )
}

export default ModifyCartPage