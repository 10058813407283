import React, { useContext, useEffect, useState } from 'react';
import Price from '../shop/Price';
import CartContext from '../../../contexts/CartContext';
import ShippingClient from '../../../api/ShippingClient';
import { FormControlLabel, Radio } from '@mui/material';

type Props = {
  items: ICartItem[],
  method?: string,
  name?: string,
  radio?: boolean,
  alwaysVisible?: boolean,
}


const Shipping = ({ items, method, radio, alwaysVisible }: Props) => { 
  // Get the cart from the context
  const subtotal = items.reduce((subtotal, item) => subtotal + (item.count * (item.item.price ? item.item.price : 0)), 0);
  const {cart, setCartState} = useContext(CartContext);
  const myMethod = method ? method : cart.shippingMethod;
  const [shipping, setShipping] = useState<ShippingDetails|undefined>();

  let discountedSubtotal = cart.discountAmount ? subtotal - cart.discountAmount : subtotal;

  useEffect(() => {
    const doGetShipping = async() => {
      if (discountedSubtotal) {
        const shippingDetails = myMethod ?
          await ShippingClient.getShipping(myMethod, cart, discountedSubtotal, alwaysVisible) :
          await ShippingClient.doGetBestShippingEstimate(cart);
        setShipping(shippingDetails);
      }
    }

    doGetShipping();
  }, [myMethod, cart.deliveryAddress, discountedSubtotal]);

  if (!alwaysVisible && shipping?.code === "ERROR") {
    if (cart.shippingMethod === method) {
      setCartState({
        ...cart,
        shippingMethod: undefined
      });
    }
    return (<></>);
  }

  const usePrice = shipping?.freeShippingThreshold && discountedSubtotal >= shipping.freeShippingThreshold ? 0 : shipping?.price;

  const getShippingName = (ship?: ShippingDetails ): string => {
    if (!ship) {
      return '';
    }
    switch (ship.code) {
      case "LOCAL": return ship.name;
      case "PICKUP": return ship.name;
      default:
        return "Standard shipping";
    }
  }

  const getRadioLabel = () => {
    // Full display with name and value
    if (usePrice && usePrice < 0) {
      return (
        <div className='shipping-descr'>
          <div className='left'>
            <div>{shipping?.name}</div>
          </div>
          <div className='centre'>N/A</div>
        </div>
      );
    }
    if (shipping?.freeShippingThreshold) {
      if (shipping === undefined || usePrice === undefined || usePrice < 0) {
        return (
          <>
          </>
        )
      }

      return (
        <div className='shipping-descr'>
        <div className='left'>
          <div>{getShippingName(shipping)}</div>
          <div className='free-shipping'>(Free shipping at <Price price={shipping?.freeShippingThreshold}/>)</div>
        </div>
        <div className='centre'><Price price={usePrice} showZero={true}/></div>
      </div>
      );
    }
    return (
        <div className='shipping-descr'>
          <div className='left'>
            <div>{getShippingName(shipping)}</div>
          </div>
          <div className='centre'><Price price={usePrice} showZero={true}/></div>
        </div>
    )
  }


  // If there is no name we want a simplified display
  if (!radio) {
    if (shipping === undefined) {
      return (
        <>
        </>
      )
    }
  
    if (usePrice === undefined || usePrice < 0) {
      return (
        <>
        </>
      )
    }

    if (shipping?.freeShippingThreshold) {
      return (
        <>
          <div><Price price={usePrice} showZero={true}/></div>
          <div className='free-shipping'>(Free shipping at <Price price={shipping.freeShippingThreshold}/>)</div>
        </>
      )
    }
    return (
      <>
          <div><Price price={usePrice} showZero={true}/></div>
      </>
    )  
  }

  return (
    <FormControlLabel value={method} control={<Radio />} label={getRadioLabel()}/>
  );
}

export default Shipping