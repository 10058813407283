import React, { ChangeEvent, useContext } from 'react';
import CartContext from '../../../contexts/CartContext';
import { FormControl, FormLabel, SelectChangeEvent, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MuiPhoneNumber from 'material-ui-phone-number-2';

const CustomerContact = () => {

  // Get the cart from the context
  const {cart, setCartState} = useContext(CartContext);

  const updateCustomerAddressField = (name: string, value: string) => {
    setCartState({
      ...cart,
      customerAddress: {
        ...cart.customerAddress,
        [name]: value
      }});
  }
  
  const onFirstNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCustomerAddressField('firstName', event.target.value);
  }

  const onLastNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCustomerAddressField('lastName', event.target.value);
  }

  const onPhoneChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCustomerAddressField('phone', event.target.value);
  }

  // const onPhoneChanged = (event:string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   updateCustomerAddressField('phone', event as string);
  // }

  const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCustomerAddressField('email', event.target.value);
  }

  return (
    <Card>
      <CardContent>
        <FormControl component="fieldset">
          <FormLabel component="legend" className='checkout-form-legend'>Customer details</FormLabel>
          <div className='checkout-form-fields'>
            <TextField required id="firstName" label="First Name" value={cart.customerAddress.firstName} onChange={onFirstNameChanged}/>
            <TextField required id="lastName" label="Last Name" value={cart.customerAddress.lastName} onChange={onLastNameChanged}/>
            <TextField required id="email" label="Email Address" value={cart.customerAddress.email} onChange={onEmailChanged}/>
            {/* <div className='phone-input'>

              <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required css-1kty9di-MuiFormLabel-root-MuiInputLabel-root" id="phoneNumber-label">
                Phone Number
              <span aria-hidden="true" className="MuiInputLabel-asterisk MuiFormLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span>
              </label> */}
              <TextField required id="phone" label="Phone Number" value={cart.customerAddress.phone} onChange={onPhoneChanged}/>

              {/* <MuiPhoneNumber required
                label="Phone Number"
                variant="outlined"
                defaultCountry={'ca'}
                value={cart.customerAddress.phone}
                onChange={onPhoneChanged}
                fullWidth={true}/> */}
            {/* </div> */}
          </div>
        </FormControl>
      </CardContent>
    </Card>
  )
}

export default CustomerContact