import axios, { AxiosResponse } from "axios"
import { CartContents } from "../contexts/CartContext";
import AbstractCommonApi from './AbstractCommonClient';

class Shipping extends AbstractCommonApi {

  getRawShippingEstimate = async (province: string, postal: string, value: number): Promise<ShippingDetails[]> => {

    var formData: Record<string, any> = {};
    formData['province'] = province;
    formData['postal'] = postal;
    formData['value'] = value;

    try {
        const results = await axios.post(`${this.extendedUrl}/api/v1/shipping/estimate`, formData);
        return results.data;
    } catch (error: any) {
      console.log(error);
        throw new Error(error);
    }
  }

  getShippingEstimate = async (cart: CartContents): Promise<ShippingDetails[]> => {

    const deliveryAddress = cart.deliveryAddress;

    var formData: Record<string, any> = {};
    formData['postal'] = deliveryAddress.postal;
    formData['cart'] = cart;

    try {
        const results = await axios.post(`${this.extendedUrl}/api/v1/shipping/estimate`, formData);
        return results.data;
    } catch (error: any) {
      console.log(error);
        throw new Error(error);
    }
  }

  doGetBestShippingEstimate = async(cart: CartContents): Promise<ShippingDetails|undefined> => {
        if (cart.deliveryAddress.postal) {
          const shippingDetailsList = await this.getShippingEstimate(cart);
    
          if (shippingDetailsList) {
            let useShipping: ShippingDetails | undefined = undefined;
            for (let i = 0; i < shippingDetailsList.length; i++) {
              const ship = shippingDetailsList[i];
              switch (ship.code) {
                case "PICKUP":
                case "LOCAL":
                  break;
                default:
                  if (!useShipping) {
                    if (ship.freeShippingThreshold) {
                      useShipping = ship;
                    }
                  }
                  else {
                    if (ship.freeShippingThreshold && useShipping.freeShippingThreshold &&
                        ship.freeShippingThreshold < useShipping.freeShippingThreshold) {
                      useShipping = ship;
                    }
                  }
                  break;
              }
            }
            return useShipping;
          }
        }
        return undefined;
    }
      
    getShipping = async (
        method: string,
        cart: CartContents,
        value: number,
        alwaysVisible?: boolean): Promise<ShippingDetails> => {

        const deliveryAddress = cart.deliveryAddress;

        var formData: Record<string, any> = {};
        formData['street'] = deliveryAddress.street;
        formData['city'] = deliveryAddress.city;
        formData['province'] = deliveryAddress.province;
        formData['postal'] = deliveryAddress.postal;
        formData['value'] = value;
        formData['cart'] = cart;

        try {
            const result = await axios.post(`${this.extendedUrl}/api/v1/shipping`, formData);

            const shippingOptions: ShippingDetails[] = result.data;
            for (let i = 0; i < shippingOptions.length; i++) {
                const shipping = shippingOptions[i];
                if (method === shipping.code) {
                    return shipping;
                }
            }
            if (alwaysVisible && method === "LOCAL") {
                return {
                    code: "ERROR",
                    name: "Local Delivery",
                    minDays: 0,
                    maxDays: 1,
                    price: -1
                };
            }
            return {code: 'ERROR', name: 'Unknown', price: -1}
        } catch (error: any) {
            throw new Error(error);
        }
    }

    getCities = async (province?: string): Promise<string[]> => {
        if (!province) {
        return [];
        }
        try {
        const roles: AxiosResponse<string[]> = await axios.get(`${this.extendedUrl}/api/v1/shipping/cities/${province}`);
        if (!roles.data) {
            return [];
        }
    
        return roles.data;
        } catch (error: any) {
        throw new Error(error);
        }  
    }
}

export default new Shipping();
