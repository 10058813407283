
// import React from 'react';

import WelcomeText from "../widgets/WelcomeText"

const About = () => {
  return (
    <div id="main">
      <WelcomeText/>
    </div>
  )
}

export default About


