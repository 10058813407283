import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import Header from './components/widgets/Header';
import Footer from './components/widgets/Footer';
import Home from './components/pages/Home';
import Store from './components/pages/Store';
import CartContext, { CartContents } from './contexts/CartContext'
import CategoryContext, { CategoryState } from './contexts/CategoryContext'
import ConfigContext, { ConfigState } from './contexts/ConfigContext'
import useStickyState from "./hooks/stickyState";
import About from "./components/pages/About";
import ExportCsv from './components/pages/ExportCsv';
import API from './API';
import CartDrawer from "./components/widgets/cart/CartDrawer";
import ModifyCartPage from "./components/pages/ModifyCartPage";
import CheckoutPage from "./components/pages/CheckoutPage";
import MemberPage from "./components/pages/MemberPage";
import AgeGate from "./components/auth/AgeGate";
import InvoicePage from "./components/pages/InvoicePage";
import ContactPage from "./components/pages/ContactPage";
import Ungated from "./components/auth/Ungated";
import TOS from "./components/pages/legal/TOS";
import CategoryDrawer from "./components/widgets/category/CategoryDrawer";
import {Helmet} from "react-helmet";
import SiteDisabled from "./components/auth/SiteDisabled";
import ConfigTemplate from "./components/auth/Config";
import Style from "./components/widgets/Style";
import Florist from "./components/custom/ourhouse/Florist";

// Lazy load these things for speed. Users are less likely to visit these pages.
const Privacy = React.lazy(() => import("./components/pages/legal/Privacy"));
const Shipping = React.lazy(() => import("./components/pages/legal/Shipping"));
const Returns = React.lazy(() => import("./components/pages/legal/Returns"));
const Payment = React.lazy(() => import("./components/pages/legal/Payment"));
const Order = React.lazy(() => import("./components/pages/legal/Order"));

const App = () => {
  const [cartOpen, setCartOpen] = React.useState(false);
  const [deptOpen, setDeptOpen] = React.useState(false);
  const [areFiltersOpen, setAreFiltersOpen] = React.useState<boolean>(true);

  const [ config, setConfig ] = React.useState<Record<string, any>>({});
  
  const [cart, setCartState] = useStickyState<CartContents>({
    items: {},
    deliveryAddress: {
      firstName: '',
      lastName: '',
      street: '',
      additional: '',
      city: '',
      province: '',
      postal: ''
    },
    cardholderAddress: {
      firstName: '',
      lastName: '',
      street: '',
      additional: '',
      city: '',
      province: '',
      postal: ''
    },
    customerAddress: {},
    distance: -1,
    shippingMethod: '',
    paymentMethod: '',
  }, 'cart');

  const [category, setCategoryState] = useState<CategoryState>({
    categories: []
  });

  if (!cart.deliveryAddress) {
    cart.deliveryAddress = {
      firstName: '',
      lastName: '',
      street: '',
      additional: '',
      city: '',
      province: '',
      postal: ''
    }
  }

  if (!cart.deliveryAddress.firstName) {cart.deliveryAddress.firstName=''}
  if (!cart.deliveryAddress.lastName) {cart.deliveryAddress.lastName=''}
  if (!cart.deliveryAddress.street) {cart.deliveryAddress.street=''}
  if (!cart.deliveryAddress.additional) {cart.deliveryAddress.additional=''}
  if (!cart.deliveryAddress.city) {cart.deliveryAddress.city=''}
  if (!cart.deliveryAddress.province) {cart.deliveryAddress.province=''}
  if (!cart.deliveryAddress.postal) {cart.deliveryAddress.postal=''}
  if (!cart.shippingMethod) {cart.shippingMethod=''}
  if (!cart.paymentMethod) {cart.paymentMethod=''}

  if (!cart.customerAddress) {
    cart.customerAddress = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      dob: {}
    }
  }

  if (!cart.customerAddress.firstName) {cart.customerAddress.firstName=''}
  if (!cart.customerAddress.lastName) {cart.customerAddress.lastName=''}
  if (!cart.customerAddress.email) {cart.customerAddress.email=''}
  if (!cart.customerAddress.phone) {cart.customerAddress.phone=''}
  if (!cart.customerAddress.dob) {cart.customerAddress.dob = {}}

  if (!cart.items) {
    cart.items = {};
  }

  const fetchCategories = async () => {
    try {
      // const allRoots = await getCategories();
      // const roots = allRoots.filter(applyRootFilter);
      // const myCategories = roots.flatMap(category => {
      //   return category.children;
      // });
      const roots = await API.getCategories();

      setCategoryState({
        categories: roots
      });
    }
    catch (err: any) {
      console.error(err.message);
    }
  }

  /**
   * Load categories on startup
   */
  useEffect(() => {  
    fetchCategories();
  }, []);

  /**
   * Request refresh
   */
  const doCategoryRefresh = () => {
    fetchCategories();
  }

  return (
    <>      
      <ConfigContext.Provider value={{ config, setConfig }}>
        <Style></Style>

        <Router>
          <ConfigTemplate>
              <CartContext.Provider value={{ cart, setCartState }}>
                <CategoryContext.Provider value={{ category, setCategoryState, doCategoryRefresh }}>
                  <main className='App'>
                    <Suspense fallback={<div>Loading...</div>}>
                    
                      <Header
                        cartOpen={cartOpen}
                        setCartOpen={setCartOpen}
                        areFiltersOpen={areFiltersOpen}
                        setAreFiltersOpen={setAreFiltersOpen}
                        deptOpen={deptOpen}
                        setDeptOpen={setDeptOpen}
                        />
                      <div className='app-body'>
                          <CategoryDrawer deptOpen={deptOpen} setDeptOpen={setDeptOpen}/>
                          <CartDrawer cartOpen={cartOpen} setCartOpen={setCartOpen}/>
                          {/* A <Switch> looks through its children <Route>s and
                            renders the first one that matches the current URL. */}
                          <Ungated>
                            <Switch>
                              <Route exact path="/privacy"><Privacy /></Route>
                              <Route exact path="/tos"><TOS /></Route>
                              <Route exact path="/payment"><Payment /></Route>
                              <Route exact path="/ordering"><Order /></Route>
                              <Route exact path="/shipping"><Shipping /></Route>
                              <Route exact path="/returns"><Returns /></Route>
                              <Route exact path="/contact"><ContactPage /></Route>
                            </Switch>
                          </Ungated>
        
                          <AgeGate>
                            <SiteDisabled>
                            <Switch>
                              <Redirect from="//*" to="/*" />
                              <Redirect from="*//*" to="*/*" />

                              <Route path="/privacy"><Privacy /></Route>
                              <Route path="/tos"><TOS /></Route>
                              <Route path="/payment"><Payment /></Route>
                              <Route path="/ordering"><Order /></Route>
                              <Route path="/shipping"><Shipping /></Route>
                              <Route path="/returns"><Returns /></Route>
                              <Route path="/contact"><ContactPage /></Route>

                              {/* Our Hose pages */}
                              {/* FIXME: These should be blocked on other domains */}
                              <Route path="/florist"><Florist /></Route>

                              <Route path="/order/cart">
                                <ModifyCartPage />
                              </Route>
                              <Route path="/order/checkout">
                                <CheckoutPage />
                              </Route>
                              <Route path="/order/:orderId">
                                <InvoicePage />
                              </Route>
                              <Route path="/about">
                                <About />
                              </Route>
                              <Route path="/category/:group/:categoryId">
                                <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                              </Route>
                              <Route path="/category/:categoryId">
                                <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                              </Route>
                              <Route path="/category">
                                <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                              </Route>
                              {/* <Route path="/search/login">
                                <LoginRedirectorPage/>
                              </Route> */}
                              <Route path="/search/:searchText">
                                <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                              </Route>
                              <Route path="/export/csv">
                                <ExportCsv />
                              </Route>
                              <Route path="/item/:itemId">
                                <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                              </Route>
                              <Route path="/members">
                                <MemberPage/>
                              </Route>
                              <Route path="/">
                                <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                              </Route>
                            </Switch>
                            </SiteDisabled>
                          </AgeGate>
                      </div>
                      <Footer />
                    
                    </Suspense>
                  </main>
                </CategoryContext.Provider>
              </CartContext.Provider>
          </ConfigTemplate>
        </Router>
      </ConfigContext.Provider>
    </>
  )
}

export default App