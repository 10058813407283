import React, { useContext } from 'react';
import PrimarySearchAppBar from './quickbar/PrimarySearchAppBar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useHistory } from 'react-router-dom';
import ConfigContext from '../../contexts/ConfigContext';
import SearchField from './quickbar/SearchField';

type Props = {
  cartOpen: boolean,
  setCartOpen: React.Dispatch<React.SetStateAction<boolean>>,
  areFiltersOpen: boolean,
  setAreFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>,
  deptOpen: boolean,
  setDeptOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Header = ({ deptOpen, setDeptOpen, cartOpen, setCartOpen, areFiltersOpen, setAreFiltersOpen }: Props) => {
  const [scrolled,setScrolled]=React.useState(false);
  const history = useHistory();
  const {config} = useContext(ConfigContext);
  const menus = config.general.menus;

  const fontStyle = {} as React.CSSProperties;
  if (config.general?.fonts?.menu) {
    fontStyle['fontFamily'] = config.general.fonts.menu;
    fontStyle['textTransform'] = 'uppercase';
  }

  const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 100 ){
      setScrolled(true);
    }
    else{
      setScrolled(false);
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll',handleScroll)
  })

  let bufferClasses=['buffer'];
  let navbarClasses=['navbar'];
  if(scrolled){
    navbarClasses.push('scrolled');
    bufferClasses.push('scrolled');
  }

  return (
    <>
    <div id="header" className={navbarClasses.join(" ")}>
      <PrimarySearchAppBar deptOpen={deptOpen} setDeptOpen={setDeptOpen} cartOpen={cartOpen} setCartOpen={setCartOpen} areFiltersOpen={areFiltersOpen} setAreFiltersOpen={setAreFiltersOpen}/>
      {/* <ScrollableTabsButtonAuto/> */}

      <div className='static-menu-desktop'>
        <ButtonGroup variant="text">
          {menus.map((menu: any) => {
            const name = menu.name;
            const url = menu.url;
            if (url.startsWith('/')) {
              return (<Button style={fontStyle} onClick={() => {console.log(`history.push(${url})`); history.push(url);}}>{name}</Button>);
            }
            return (<Button style={fontStyle} onClick={() => {window.open(url);}}>{name}</Button>);
          })}
        </ButtonGroup>
      </div>

      <div className='static-menu-mobile'>
        <SearchField display='mobile'/>
      </div>
    </div>
    <div id='buffer' className={bufferClasses.join(" ")}>

    </div>
    </>
  )
}

export default Header;