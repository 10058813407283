import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
type Props = {
  children: React.ReactNode;
  enabled: boolean;
}

const ProgressOverlay = ({children, enabled}: Props)  => {

  if (enabled) {
    return (
      <>
        <div className='masked'>
          <div className='prominent'>Loading...</div>
          <LinearProgress />
        </div>
        {children}
      </>
    )
  }

  return (
      <>
          {children}
      </>
  )
}

export default ProgressOverlay
