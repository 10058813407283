import ShippingClient from "../api/ShippingClient";
import { CartContents } from "../contexts/CartContext";


class CartUtils {
    cart: CartContents;
    discountedSubtotal: number = 0;
    subtotal: number;
    shipping: number | undefined;

    constructor(cart: CartContents) {
        this.cart = cart;

        const items: ICartItem[] = Object.values(cart.items);

        this.subtotal = items.reduce((subtotal, item) => subtotal + (item.count * (item.item.price ? item.item.price : 0)), 0);

        this.discountedSubtotal = cart.discountAmount ? this.subtotal - cart.discountAmount : this.subtotal;
    }

    getShipping = async(): Promise<number|undefined> => {
        if (!this.shipping) {
            if (this.cart.shippingMethod) {
                const shippingDetails = await ShippingClient.getShipping(this.cart.shippingMethod, this.cart, this.discountedSubtotal);
                if (shippingDetails !== undefined) {
                    if (this.discountedSubtotal) {
                        if (shippingDetails.freeShippingThreshold && this.discountedSubtotal >= shippingDetails.freeShippingThreshold) {
                            this.shipping = 0;
                        }
                        else {
                            this.shipping = shippingDetails.price;
                            if (this.shipping < 0) {
                                this.shipping = undefined;
                            }
                        }
                    }
                }
            }
        }
        return this.shipping;
    }
      
    getTotal = async (): Promise<number|undefined> => {
        const shipping = await this.getShipping();
        if (shipping === undefined) {
            return undefined;
        }
        const taxes = Math.round((this.discountedSubtotal + shipping) * 0.15);
        const total = this.discountedSubtotal + shipping + taxes;
        return total;
    }

    getEnvironment = (): string => {
        let testName = this.cart.deliveryAddress?.firstName ? this.cart.deliveryAddress?.firstName : '';
        testName = testName.toUpperCase();
      
        if (testName === "TEST") {
            return "TEST";
        }
        else {
            return "PROD";
        }
    }
}

export default CartUtils;