import React, { useContext } from 'react';
import CartContext from '../../../contexts/CartContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const ContactPanel = () => {
  // Get the cart from the context
  const {cart, setCartState} = useContext(CartContext);

  return (
    <>
      <div>
        Contact
      </div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableBody>
          <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{cart.customerAddress.firstName} {cart.customerAddress.lastName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone</TableCell>
              <TableCell>{cart.customerAddress.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>{cart.customerAddress.email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ContactPanel