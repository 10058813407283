import React, { useEffect } from 'react';
import CustomerContact from '../CustomerContact';
import TotalsPanel from '../../cart/TotalsPanel';

type Props = {
  setNextName: React.Dispatch<React.SetStateAction<string>>;
  setPreNextAction: React.Dispatch<React.SetStateAction<() => () => Promise<boolean>>>;
}

const Step2 = ({setNextName, setPreNextAction}: Props) => {
  useEffect(() => {
    setPreNextAction(() => async () => {return true});
    setNextName("Next");
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='checkout-parent'>
      <div className='checkout-main'>
        <CustomerContact/>
      </div>
      <div className='checkout-right'>
        <TotalsPanel hideDiscount={true} hideTax={true} hideTotal={true} hideInactive={true}/>
      </div>
    </div>
  )
}

export default Step2