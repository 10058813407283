import { useContext } from 'react';
import ReactMarkdown from 'react-markdown'
import ConfigContext from '../../../contexts/ConfigContext';

const TOS = () => {
  const {config} = useContext(ConfigContext);

  const WEBSITE = config.general.url;
  const EMAIL = config.general.email;
  const PHONE = config.general.phone;
  const MODIFIED_AT = 'August 30, 2021';
  const BUSINESS_NAME = config.general.product_name;

  const providers = config.general.payment_providers as Record<string,Record<string, string>>;
  let PAYMENT_PROVIDER_NAME = '';
  for(let value of Object.values(providers)) {
    if (PAYMENT_PROVIDER_NAME) {
      PAYMENT_PROVIDER_NAME += ' and ';
    }
    PAYMENT_PROVIDER_NAME += ` ${value.name} (${value.url})`;
  }

  const text = `
  # Website Terms of Use

  ## Use and Contents

  This page contains the terms and conditions for use of the website ${WEBSITE}. This document is a legal agreement between you, the website user, and ${BUSINESS_NAME} (also referred to as the “Company”, “we”, or “us”).

  BY CLICKING “ACCEPT”, YOU AGREE TO BE BOUND BY AND TO COMPLY WITH THESE TERMS AND CONDITIONS AND WITH OUR PRIVACY POLICY. IF YOU DO NOT AGREE, DO NOT CLICK “ACCEPT” AND DO NOT ACCESS THE WEBSITE.

  By using this website, you agree that you have reached THE AGE OF MAJORITY AND ARE OF LEGAL AGE TO CONSUME CANNABIS in your province or territory of residence. If you are not yet of the age of majority and of legal age to consume cannabis, or are accessing this website on behalf of someone who may not yet legally consume cannabis, you are prohibited from accessing this website and must immediately leave.

  We may update the terms of use from time to time. Any changes will take effect immediately upon being posted to the website. You agree to review the website terms of use and privacy policy for changes, and by continuing to use the website, you will be deemed to have accepted all changes.

  We may update the website content from time to time, and may elect to change or withdraw its contents or restrict access at any point in time. We are not liable if, for any reason, our website content has been restricted or is not available.

  ## Intellectual Property

  This website’s contents, features and functionality, including all information, software, code, data text, displays, graphics, photographs, images, video, audio, music, broadcast, design, presentation, website layout, selection, and arrangement are protected by intellectual property rights such as copyright, trademark, patent, trade secret or other proprietary rights belonging to us (the Company), or our licensors or others. 

  You may not use any intellectual property belonging to us, including the Company name or logo, without our prior written consent. Use of any intellectual property belonging to others that may appear on our website is prohibited without the authorization of their respective owners, and could result in legal action against you.

  You may use the website only for your personal and non-commercial use. You may not reproduce or republish any part of this website, except as may be required to properly view the website (through temporary storage or caching by your computer) or for lawful personal purposes (such as downloading or printing a copy of your purchase information). We may require, at our option, that you return or destroy any unauthorized copies or downloads of our website or its contents, and by accepting these terms of use, you agree to do so.

  All rights not expressly granted are reserved. Any unauthorized use of website content will constitute a breach of intellectual property rights and may result in legal action being taken against you.

  ## Conditions of Use

  You agree that you will only use this website for lawful purposes, in accordance with these terms and conditions. You agree that you will not submit any information to our website that:
    (a) in any manner violates any applicable international, federal, provincial or municipal law or regulation, including intellectual property rights and privacy rights of others;
    (b)  in any manner violates the terms of use of any third party website that is linked to this website (including supplier websites and social media);
    (c) is intended to, or does, result in discrimination, harassment, or criminal conduct or exposes minors to inappropriate or illegal content, or is likely, to your knowledge, to be unwelcome to any third party; 
    (d) is knowingly false, inaccurate or misleading.

  Should we become aware of any misuse of the website, we may investigate, take appropriate legal action, including (without limitation) referral to law enforcement and/or notifying the harmed party of any illegal or unauthorized use of the website. You acknowledge that we have the right and obligation to cooperate fully with law enforcement, including to comply with any request to disclose your identity or other information you submitted on or through the website. YOU AGREE TO WAIVE AND HOLD HARMLESS THE COMPANY (AND ANY RELATED ENTITIES, EMPLOYEES, CONTRACTORS, AGENTS, DIRECTORS, OFFICERS, SUPPLIERS AND SUCCESSORS) FROM ANY AND ALL CLAIMS RELATED TO INVESTIGATIONS BY EITHER THE COMPANY OR LAW ENFORCEMENT.

  ## Privacy Policy

  Your use of the website is subject to our [Privacy Policy](/privacy). BY CLICKING “ACCEPT” TO THESE TERMS OF USE, YOU ALSO AGREE TO BE BOUND BY THE PRIVACY POLICY.

  ## No Reliance

  Although we make reasonable efforts to keep the contents of our website up to date, we make no representations or warranties that our contents are accurate, complete or current. In the event that an item is purchased through our website but is no longer available at the time of processing, we will issue you a refund in accordance with our [refund policy](/refunds).
  
  We are not responsible for the contents of any third-party information that is linked from or to our website. If you choose to access a third-party website through our website, you do so at your own risk and subject to the terms and condition of use for that site. You agree that we are not responsible for any damage that may arise as a result of your choice to click on a link to a third-party website.
  
  You may link to our website or “share” content from our website on social media, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link or share content in such a way as to suggest any form of association, approval, or endorsement on our part where none exists. We may at any time withdraw our consent to allow you to link to our website or share our content.
  
  ## Location
  
  We are based in Nova Scotia, Canada, and are providing this website only for the use of persons located in Canada. This website is not intended for use in any other jurisdiction, and if you choose to access this website from outside of Canada, you do so at your own risk and are responsible for compliance with your local laws.
  
  ## Limitation of Liability and Waiver

  YOU UNDERSTAND AND AGREE THAT YOUR USE OF THIS WEBSITE AND ITS CONTENTS ARE UNDERTAKEN VOLUNTARILY AND ENTIRELY AT YOUR OWN RISK. ANY ITEMS PROVIDED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, SUCH AS SUITABILITY OR FITNESS FOR PURPOSE. THIS STATEMENT DOES NOT EXCLUDE WARRANTIES THAT ARE REQUIRED BY LAW.

  NO REPRESENTATION OR WARRANTY IS MADE AS TO THE ACCURACY, COMPLETENESS, CURRENCY OR AVAILABILITY OF THE WEBSITE OR ITS CONTENTS. WITHOUT LIMITING THE FOREGOING, NO REPRESENTATION IS MADE AS TO THE RELIABILITY OF THE WEBSITE OR THAT THE WEBSITE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULLEST EXTENT PROVIDED BY LAW, WE ARE NOT RESPONSIBLE FOR ANY HARM OR DAMAGE THAT YOU MAY SUFFER BY ANY VIRUSES, MALWARE OR OTHER ATTACKS THAT ACCESS YOUR COMPUTER THROUGH THE WEBSITE OR THROUGH ANY MATERIAL POSTED ON THE WEBSITE OR LINKED THROUGH THE WEBSITE.

  EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL THE COMPANY OR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE WEBSITE, ANY LINKED WEBSITES OR SUCH OTHER THIRD-PARTY WEBSITES, NOR ANY WEBSITE CONTENT, MATERIALS, POSTING, OR INFORMATION THEREON EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW.
  
  You agree to defend, hold harmless and indemnify the Company, and its parent, subsidiaries, affiliates and their respective directors, officers, employees, agents, service providers contractors, licensors, licensees, suppliers, assigns and successors from any claims, liabilities and fees (including reasonable legal expenses) arising out of your breach of these terms of use or of your use of the website for purposes other than those authorized under this agreement. 
  
  ## Waiver
  
  Failure or delay in exercising any right, remedy or power under this agreement shall not constitute a waiver thereof. 
  
  ## Severability
  
  If any term or condition of this agreement is deemed to be invalid, illegal or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of these terms and conditions of use or invalidate or render unenforceable such term or provision in any other jurisdiction.
  
  ## Contact and Version
  
  Should you become aware of any misuse of this website or its contents, please report it immediately to ${EMAIL}. Please also feel free to send us any questions, comments or concerns that you may have.
  
  These terms and conditions of use were last updated on ${MODIFIED_AT} and are effective from that date. We may change the terms and conditions of use of our website from time to time in order to ensure compliance with our legal and regulatory obligations, and will post these changes on our website
  `;

  return (
    <div id="legal-main" className='flex-div'>
      <div className='legal-section'>
        <ReactMarkdown>{text}</ReactMarkdown>
      </div>
    </div>
  )
}

export default TOS