import React, { useContext, useState } from 'react';
import CartContext from '../../contexts/CartContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import ConfigContext from '../../contexts/ConfigContext';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';


/**
 * Gather whatever identity information we can and make it
 * available.
 */
const AgeGate : React.FunctionComponent<{}> = props  => {
    const {config} = useContext(ConfigContext);
    const {cart, setCartState} = useContext(CartContext);
    const [ageCheck, setAgeCheck] = React.useState<boolean>(cart.customerAddress?.ageCheck === true);
    const [tosCheck, setTosCheck] = React.useState<boolean>(cart.customerAddress?.tosCheck === true);
    const [privacyCheck, setPrivacyCheck] = React.useState<boolean>(cart.customerAddress?.privacyCheck === true);
    const [postal, setPostal] = useState<string>(cart.deliveryAddress.postal ? cart.deliveryAddress.postal : '');

    const fontStyle = {} as React.CSSProperties;
    if (config.general?.fonts?.title) {
      fontStyle['fontFamily'] = config.general.fonts.title;
    }
  
    const commit = () => {
      const usePostal = postal ? postal : undefined;
      setCartState({
          ...cart,
          customerAddress: {
              ...cart.customerAddress,
              ageCheck: ageCheck,
              tosCheck: tosCheck,
              privacyCheck: privacyCheck
          },
          deliveryAddress: {
            ...cart.deliveryAddress,
            postal: usePostal
          }
      });
    }

  const passAgeCheck = () => {
    const usePostal = postal ? postal : undefined;
    setCartState({
      ...cart,
      customerAddress: {
          ...cart.customerAddress,
          ageCheck: true,
          tosCheck: tosCheck,
          privacyCheck: privacyCheck
      },
      deliveryAddress: {
        ...cart.deliveryAddress,
        postal: usePostal
      }
    });
  }

  const onPostalChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPostal(value.toUpperCase());
  }

  const cancel = () => {
    window.location.assign('https://google.ca');
  }

  const handleAgeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgeCheck(event.target.checked);
  }

  const handleTosCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTosCheck(event.target.checked);
  };

  const handlePrivacyCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacyCheck(event.target.checked);
  };

    const getTosText = () => {
        return (
            <>
                I have read and agree with the <Link to='/tos'>Terms of Service (TOS)</Link>
            </>
        )
    }

    const getPrivacyText = () => {
        return (
        <>
            I have read and agree with the <Link to='/privacy'>Privacy Policy</Link>
        </>
        )
    }

    if (!config.general.age_check) {
        return (
          <>
              {props.children}
          </>
        );
    }

    if (!cart.customerAddress.ageCheck) {
        return (
            <div id='id-main' className='flex-div'>
                <Card className='age-validation'>
                    <CardContent>
                        <form noValidate autoComplete="off">
                            <div className='title'>Welcome to <span className='business-name'>{config.general.product_name}</span>,</div>
                            {/* <div className='subtitle'>Due to local laws, age verification is required.</div> */}
                            <div className='text'>
                              This website contains adult material and is only suitable for those 19 years or older.
                              Click OK only if you are at least 19 years of age.
                            </div>
                            {/* <div className='text'>
                              <b>Optional</b>: Enter your postal code for on-demand calculation of delivery and calculation of your
                              free shipping rate.
                            </div>
                            <div className='form'>
                              <FormControl>
                                <TextField
                                  label="Postal Code"
                                  value={postal}
                                  onChange={onPostalChanged}
                                  name="postal"
                                  id="postal"
                                  // inputComponent={TextMaskCustom as any}
                                />
                              </FormControl>
                            </div> */}
                            <Button variant="contained" color="primary" onClick={() => {passAgeCheck();}}>
                                OK
                            </Button>
                            <Button variant="contained" color="secondary" onClick={cancel}>
                                Cancel
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </div>
        )
    }

    return (
        <>
            {props.children}
        </>
    )

}

export default AgeGate
