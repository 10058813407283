import React, { useContext, useEffect, useState } from 'react';
import Price from '../shop/Price';
import CartContext from '../../../contexts/CartContext';
import CartUtils from '../../../utils/CartUtils';

type Props = {
  items: ICartItem[]
}


const Total = ({ items }: Props) => {
  const {cart, setCartState} = useContext(CartContext);
  const [total, setTotal] = useState<number|undefined>(undefined);

  useEffect(() => {
    const doGetTotal = async() => {
      const utils = new CartUtils(cart);
      setTotal(await utils.getTotal());
    }

    doGetTotal();
  }, [cart]);

  if (total === undefined) {
    return (<></>)
  }
  
  return (
    <Price id='purchase-total' price={total}/>
  )
}

export default Total