import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Shop from '../widgets/shop/Shop';
import CategoryPanel from '../widgets/category/CategoryPanel';
import ConfigContext from '../../contexts/ConfigContext';
import React from 'react';
import ItemDetailsPage from './ItemDetailsPage';

type Props = {
  areFiltersOpen: boolean,
  setAreFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Store = ({ areFiltersOpen, setAreFiltersOpen }: Props) => {
  const {config} = useContext(ConfigContext);
  const [defaultDisplay, setDefaultDisplay] = React.useState<string | undefined>(undefined);

  let { categoryId } = useParams<{categoryId: string}>();
  let { group } = useParams<{group: string}>();
  const categoryRoot = config.general.root_category;
  const useGroup = group ? group : categoryId && !categoryId.startsWith('#') ? categoryId : undefined;

  const filterRef = React.createRef<HTMLDivElement>();

  const [categoryManagerDisplay, setCategoryManagerDisplay] = useState<string>("none");


  useEffect(() => {
    if (filterRef && filterRef.current) {
      const myDisplay = filterRef.current.style.getPropertyValue("display");
      const calculatedDisplay = window.getComputedStyle(filterRef.current).getPropertyValue("display");
      if (myDisplay !== calculatedDisplay) {
        switch (calculatedDisplay) {
          case 'block':
            setAreFiltersOpen(true);
            setDefaultDisplay('block');
            break;
          default:
            setAreFiltersOpen(false);
            setDefaultDisplay('none');
            break;
        }
      }
    }
  }, [filterRef]);

  return (
    <>
      <ItemDetailsPage />
      <div className="store-parent">
        <CategoryPanel display={categoryManagerDisplay} setDisplay={setCategoryManagerDisplay} root={categoryRoot} group={useGroup} selectedCategory={categoryId}/>
        <div className="store-main">
          <Shop />
        </div>
      </div>
    </>
  )
}

export default Store