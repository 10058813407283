import React, { useContext, useEffect, useState } from 'react';
import CartContext from '../../../../contexts/CartContext';
import TotalsPanel from '../../cart/TotalsPanel';
import ContactPanel from '../ContactPanel';
import DiscountOptions from '../payment/DiscountOptions';
import PaymentDescription from '../payment/PaymentDescription';
import PaymentOptions from '../payment/PaymentOptions';
import ShippingPanel from '../ShippingPanel';
import ConfigContext from '../../../../contexts/ConfigContext';
import API, { uuidv4 } from '../../../../API';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useHistory } from "react-router-dom";
import IfTrue from '../../../conditions/IfTrue';

type Props = {
  setNextName: React.Dispatch<React.SetStateAction<string>>;
  setPreNextAction: React.Dispatch<React.SetStateAction<() => () => Promise<boolean>>>;
  setActiveStep?: (step: number) => void;
}

const Step4 = ({setNextName, setPreNextAction, setActiveStep}: Props) => {
  const history = useHistory();
  const {config} = useContext(ConfigContext);
  const {cart, setCartState} = useContext(CartContext);
  const [getPaymentToken, setGetPaymentToken] = React.useState<undefined | (() => () => Promise<any>)>(undefined);
  
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const setErrorMessage = (msg: string | undefined) => {
    setCartState({
      ...cart,
      lastError: msg
    })
  }

  const doSubmitOrder = async (): Promise<boolean> => {
    console.log("ZOUNDS 0: ");
    if (cart.items && getPaymentToken) {

      // 1. Submit the order
      let orderId: string | undefined;
      // The token is a special ID that is only visible during creation, and thereafter by the admin. This
      // means it can briefly be used as a secure way to do anonymous state changes, cause this data is
      // only briefly available in this scope.
      let token: string = '';
      try {
        const result: any = await API.submitOrder(cart);
        orderId = result.id;
        if (result.token) {
          token = result.token;
          console.log("TOKEN: " + token);
        }
      }
      catch (e: any) {
        console.error(JSON.stringify(e));
        const message = e.message ? e.message : "Unknown error submitting order";
        setErrorMessage(message);
        return false;
      }

      if (!orderId) {
        setErrorMessage("Error submitting order");
        return false;
      }

      let getPaymentTokenCallback = undefined;

      switch (cart.paymentMethod) {
        case "emt":
          // No payment
          break;
        
        default:
          // Pay for the order
          try {
            const getPaymentTokenMethod = getPaymentToken as any as () => Promise<any>;
            const paymentData = await getPaymentTokenMethod();

            getPaymentTokenCallback = paymentData.callback;

            setErrorMessage(undefined);

            await API.createPayment(
              paymentData.provider,
              paymentData.location,
              paymentData.environment,
              paymentData.token,
              orderId);

            console.log("PAYMENT CREATED...");
            setErrorMessage(undefined);
          }
          catch (e: any) {
            console.log("ERROR: " + e);
            console.log("ERROR: " + JSON.stringify(e));
            API.cancelOrderById(orderId, token);
            if (getPaymentTokenCallback) {
              await getPaymentTokenCallback(false);
            }
            return false;
          }

          break;
      }

      // If the order is submitted and paid, clear the cart
      const newCart = {
        ...cart,
        items: {},
        notes: undefined,
        lastOrderId: orderId,
        discountAmount: 0,
        discountCode: undefined,
        paymentMethod: undefined,
        deliveryDate: undefined,
        live: undefined
      };
      delete newCart.checkoutStep;
      setCartState(newCart);

      if (setActiveStep) {
        setActiveStep(0);
      }

      if (getPaymentTokenCallback) {
        await getPaymentTokenCallback(true);
      }

      // Everything succeeded!
      console.log(`history.push(/order/${orderId})`); 
      history.push(`/order/${orderId}`);
      return true;
    }

    return false;
  }

  useEffect(() => {
    console.log("PaymentToken is set");
    setPreNextAction(() => async () => {return await doSubmitOrder()});
  }, [getPaymentToken]);


  return (
    <>
      <div className='checkout-parent'>
        <div className='checkout-main'>
          <IfTrue condition={!!cart.lastError}>
            <Alert severity="error">
            {cart.lastError}
            </Alert>
          </IfTrue>
          {/* <DiscountOptions/> */}
          <PaymentOptions setNextName={setNextName}/>
          <PaymentDescription getPaymentToken={getPaymentToken} setGetPaymentToken={setGetPaymentToken}/>
        </div>
        <div className='checkout-right'>
          <TotalsPanel hideDiscount={false}/>
          <ContactPanel />
          <ShippingPanel />
        </div>
      </div>
    </>
  )
}

export default Step4
