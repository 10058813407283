import React from 'react';

type Props = {
  children: React.ReactNode;
  condition: boolean | undefined,
}

const IfTrue = ({ condition, children }: Props) => {

  if (condition) {
    return (
      <>
        {children}
      </>
    )
  }
  return (
    <>
    </>
  )
}

export default IfTrue