import Alert from '@mui/material/Alert';
import SpeedIcon from '@mui/icons-material/Speed';
import React, { useContext } from 'react';
import ConfigContext from '../../../contexts/ConfigContext';
import PhoneIcon from '@mui/icons-material/Phone';
import IfTrue from '../../conditions/IfTrue';

type Props = {
  notes?: string,
  specialDelivery?: boolean,
  stock?: number
}

const DeliveryNotes = ({ notes, specialDelivery, stock }: Props) => {


  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  if (!notes) {
    return (
      <>
        <IfTrue condition={specialDelivery}>
          <Alert
          iconMapping={{
            info: <PhoneIcon fontSize="inherit" />,
          }}
          severity="info" className='margin-top'>
            <div className='fast-shipping-description'>
              This item is available by <b>Special Order</b> only.
            </div>
            <div>
              <b>Special Order</b> items may include items that are over 20 lbs, or are particularly large. They
              might also include items that require special handling or information. Please call at
              (902) 343-3569 to submit special orders.
            </div>
          </Alert>
        </IfTrue>
      </>
    )
  }

  return (
    <Alert
      iconMapping={{
        success: <SpeedIcon fontSize="inherit" />,
      }}
      severity="success">{notes}</Alert>
  )
}

export default DeliveryNotes