import React, { useContext } from 'react';
import CartContext from '../../../contexts/CartContext';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModifyCartItem from './ModifyCartItem';
import { useHistory } from "react-router-dom";
import ShoppingCartTwoTone from '@mui/icons-material/ShoppingCartTwoTone';
import AlertDialog from './AlertDialog';
import ModifyCartTotals from './ModifyCartTotals';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Cloneable } from '../../../utils/Clonable';


const useStyles = makeStyles({
  table: {
  },
});

type Props = {
  readonly?: boolean,
  receipt?: boolean,
  hideDiscount?: boolean,
  hideShipping?: boolean,
  hideTax?: boolean,
  hideTotal?: boolean,
  hideInactive?: boolean,
  checkout?: boolean
}

const ModifyCartPanel = ({ readonly, checkout, receipt, hideDiscount, hideShipping, hideTax, hideTotal, hideInactive }: Props) => {
  const history = useHistory();
  const classes = useStyles();

  const [removeAllDialogOpen, setRemoveAllDialogOpen] = React.useState(false);

  // Get the cart from the context
  const {cart, setCartState} = useContext(CartContext);
  const itemList: ICartItem[] = Object.values(cart.items);

  const [open, setOpen] = React.useState<string | undefined>(undefined);

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(undefined);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleChange = (id: string, count: number) => {
    const newCart = Cloneable.deepCopy(cart);
    const item = newCart.items[id];
    if (item) {
      const diff = count - item.count;
      item.count = count;

      setCartState(newCart)

      if (diff > 0) {
        setOpen(`Added ${item.item.name} to cart`);
      }
      if (diff <= 0) {
        setOpen(`Removed ${item.item.name} from cart`);
      }
    }
  }

  const handleDelete = (id: string) => {
    const newCart = Cloneable.deepCopy(cart);
    const item = newCart.items[id];
    if (item) {
      const name = item.item.name;
      delete newCart.items[id];
      setCartState(newCart);
      setOpen(`Removed ${name} from cart`);
    }
  }

  const handleCheckout = () => {
    console.log(`history.push(/order/checkout)`); 
    history.push('/order/checkout');
    history.go(0);
    return (<></>);
  }

  const handleRemoveAll = () => {
    setRemoveAllDialogOpen(true);
  }

  const onRemoveAllClose = (success: boolean) => {
    if (success) {
      const newCart = {
        ...cart,
        items: {}
      };
      setCartState(newCart);
    }
  }

  if (itemList.length === 0) {
    return (
      <>
        <AlertDialog open={removeAllDialogOpen} setOpen={setRemoveAllDialogOpen} handleClose={onRemoveAllClose}/>
        <div className='my-cart'><ShoppingCartTwoTone fontSize='large'/> My Cart</div>
        <div className="cart-parent">
          <div className="cart-main">
            <div>Your shopping cart is empty!</div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
    <Snackbar open={!!open} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
      <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="success">
        {open}.
      </Alert>
    </Snackbar>
    <AlertDialog open={removeAllDialogOpen} setOpen={setRemoveAllDialogOpen} handleClose={onRemoveAllClose}/>
    <div className="cart-parent">
      <div className="cart-main">
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="simple table">
            <TableHead>
              <TableRow className='items-table-row'>
                <TableCell className='items-table-cell'></TableCell>
                <TableCell className='items-table-cell items-image-cell'></TableCell>
                <TableCell className='items-table-cell'>Item</TableCell>
                <TableCell className='items-table-cell' align="center">Quantity</TableCell>
                <TableCell className='items-table-cell' align="right">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemList.map((item, index) => (
                <ModifyCartItem key={index} handleChange={handleChange} handleDelete={handleDelete} item={item} readonly={readonly || receipt}/>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ModifyCartTotals checkout={checkout} hideDiscount={hideDiscount} hideShipping={hideShipping} hideTax={hideTax} hideTotal={hideTotal} readonly={readonly} hideInactive={hideInactive} receipt={receipt} handleCheckout={handleCheckout} handleRemoveAll={handleRemoveAll}/>
    </div>
    </>
  )
}

export default ModifyCartPanel