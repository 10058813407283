import IfFalse from '../../conditions/IfFalse';
import IfTrue from '../../conditions/IfTrue';
import Special from '../checkout/delivery/description/Special';

type Props = {
  item: IItem
}

const ItemBanner = ({ item }: Props) => {
  const isGroup = item?.group?.enabled && !item?.group?.variantName;
  const stockCounter = isGroup ?
    (item.group?.variantSummary?.stock) :
    Object.keys(item.inventory).reduce((sum, key) => sum + item.inventory[key].count, 0);

  return (
    <>
      <IfTrue condition={item?.special}>
        <div className='bottom-right special-order item-header'><Special/></div>
      </IfTrue>
      <IfFalse condition={item?.special}>
        <div className='bottom-right item-header'></div>
      </IfFalse>
    </>
  )
}

export default ItemBanner