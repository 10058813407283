import React from 'react';

export interface CartContents {
    items: Record<string, ICartItem>,
    deliveryAddress: DeliveryAddress,
    cardholderAddress: CardholderAddress,
    customerAddress: {
        firstName?: string,
        lastName?: string,
        email?: string,
        phone?: string,
        dob?: {
            day?: number,
            month?: number,
            year?: number,
        },
        age?: number,
        ageCheck?: boolean,
        tosCheck?: boolean,
        privacyCheck?: boolean
    },
    distance?: number,
    shippingMethod?: string,
    paymentMethod?: string,
    lastOrderId?: string,
    notes?: string,
    card?: string,
    deliveryDate?: Date,
    checkoutStep?: number,
    lastError?: string,
    discountCode?: string,
    discountAmount?: number // This is used in the UI and is not to be used on the server
};

const CartContext = React.createContext<{
    cart: CartContents,
    setCartState: React.Dispatch<React.SetStateAction<CartContents>>
}>({
    cart: {
        items: {},
        deliveryAddress: {},
        cardholderAddress: {},
        customerAddress: {}
    },
    setCartState: () => {}
});

export default CartContext;
