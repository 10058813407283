import Price from '../shop/Price';

type Props = {
  items: ICartItem[]
}


const InvoiceReviewSubtotal = ({ items }: Props) => {
  // const subtotal = items.filter(item => !item.item.name.startsWith("Shipping")).reduce((subtotal, item) => subtotal + (item.count * (item.item.price ? item.item.price : 0)), 0);
  const subtotal = items.reduce((subtotal, item) => subtotal + (item.count * (item.item.price ? item.item.price : 0)), 0);

  return (
    <Price price={subtotal}/>
  )
}

export default InvoiceReviewSubtotal