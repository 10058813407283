import Button from '@mui/material/Button';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

type Props = {
  readonly?: boolean,
  handleCheckout: () => void,
  handleRemoveAll: () => void
}

const ModifyCartButtons = ({ readonly, handleCheckout, handleRemoveAll }: Props) => {
  
  if (readonly) {
    return (<></>)
  }

  return (
    <>
      <Button className='drawer-button' variant="contained" color="secondary" startIcon={<RemoveShoppingCartIcon />} onClick={handleRemoveAll}>
          Remove All
      </Button>
    </>
  )
}

export default ModifyCartButtons