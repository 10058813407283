import InputBase from '@mui/material/InputBase/InputBase';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

type Props = {
    display: string
  }
  
  const SearchField = ({ display }: Props) => {
    const history = useHistory();

    const onSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (event.key === 'Enter') {
          const text = (event.target as HTMLInputElement).value;
          console.log("ORIGINAL SEARCH TEXT: " + text);
          const searchText = text.replace(/[^\w ]/g, '.').trim();
          console.log("Revised SEARCH TEXT: " + text);
          if (searchText) {
            console.log(`history.push(/search/${searchText})`); 
            history.push(`/search/${searchText}`);
            history.go(0);
          }
        }
      }
    
    switch (display) {
        case "desktop":
            return (
                <>
                    <div className="header-search header-search-desktop">
                        <div className="header-search-icon">
                            <SearchIcon />
                        </div>
                        <InputBase
                        placeholder="Search…"
                        className="header-input-root header-input-input"
                        inputProps={{ 'aria-label': 'search' }}
                        onKeyDown={onSearchKeyDown}
                        />
                    </div>
                </>
            );

            case "mobile":
                return (
                    <>
                        <div className="header-search header-search-mobile">
                            <div className="header-search-icon">
                                <SearchIcon />
                            </div>
                            <InputBase
                            placeholder="Search…"
                            className="header-input-root header-input-input"
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyDown={onSearchKeyDown}
                            />
                        </div>
                    </>
                );
    
    }

    return (
        <>
        </>
    );
}

export default SearchField
