import CategoryWidget from './CategoryWidget'

type Props = {
  group?: string
  selectedCategory?: string
  children: ICategory[]
}

const CategoryChildPanel = ({ children, group, selectedCategory }: Props) => {
  // const name = category.categoryData.name.replace(root + " > ", "").split(">")[0].trim();
  
  return (
    <ul>
    {children.map((category: ICategory) => (
      <CategoryWidget
        key={category.id}
        group={group}
        selectedCategory={selectedCategory}
        category = {category}
      />
    ))}
    </ul>
  )
}

export default CategoryChildPanel