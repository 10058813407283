import React, { useContext, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CartContext from '../../../../contexts/CartContext';

type Props = {
  province?: string,
  onChange?: (value: string) => void,
  onBlur?: () => void
}

const ProvinceSelector = ({ province, onChange, onBlur }: Props) => {

  const {cart, setCartState} = useContext(CartContext);
  const [selectedProvince, setSelectedProvince] = useState<string|undefined>(province);

  let disabled = cart.shippingMethod === 'LOCAL' ? true : false;

  useEffect(() => {
    if (cart.shippingMethod === 'LOCAL') {
      if (cart.deliveryAddress.province !== 'NS') {
          console.log("SETTING SELECTED PROVINCE");
          setSelectedProvince('NS');
          if (onChange) {
            onChange('NS');
          }
        }
    }
  }, [cart.shippingMethod]);


  const doChange = (event: SelectChangeEvent<string>) => {
    setSelectedProvince(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  }

  const doBlur = () => {

  }

  return (
    <FormControl className='province-selector'>
    <InputLabel required id="province-label">Province</InputLabel>
    <Select
      label="Province"
      id="province"
      value={selectedProvince}
      onChange={doChange}
      onBlur={doBlur}
      disabled={disabled}
    >
      <MenuItem value='AB'>Alberta</MenuItem>
      <MenuItem value='BC'>British Columbia</MenuItem>
      <MenuItem value='MB'>Manitoba</MenuItem>
      <MenuItem value='NB'>New Brunswick</MenuItem>
      <MenuItem value='NL'>Newfoundland and Labrador</MenuItem>
      <MenuItem value='NT'>Northwest Territories</MenuItem>
      <MenuItem value='NS'>Nova Scotia</MenuItem>
      <MenuItem value='NU'>Nunavut</MenuItem>
      <MenuItem value='ON'>Ontario</MenuItem>
      <MenuItem value='PE'>Prince Edward Island</MenuItem>
      <MenuItem value='QC'>Quebec</MenuItem>
      <MenuItem value='SK'>Saskatchewan</MenuItem>
      <MenuItem value='YT'>Yukon</MenuItem>
    </Select>
  </FormControl>
)
}

export default ProvinceSelector