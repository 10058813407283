import React, { useContext } from 'react';
import CartContext from '../../../contexts/CartContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Subtotal from './Subtotal';
import Taxes from './Taxes';
import Total from './Total';
import Shipping from './Shipping';
import Discount from './Discount';
import IfTrue from '../../conditions/IfTrue';
import { Card } from '@mui/material';
import IfFalse from '../../conditions/IfFalse';

const TAX_RATE = 0.15;

type Props = {
  hideInactive?: boolean,
  hideDiscount?: boolean,
  hideShipping?: boolean,
  hideTax?: boolean,
  hideTotal?: boolean
}

const TotalsPanel = ({hideInactive, hideDiscount, hideShipping, hideTax, hideTotal}: Props) => {
  // Get the cart from the context
  const {cart, setCartState} = useContext(CartContext);
  const items: ICartItem[] = Object.values(cart.items);

  const fastShipping = items.filter((item) => {
  // We need to handle situations where the inventory has not been updated to the new inventory style (florist!)
  const hasInventory = !!item.item.inventory

  // If inventory is old style, then assume we have some left
    const stock = hasInventory ? Object.keys(item.item.inventory).reduce((sum, key) => sum + item.item.inventory[key].count, 0) : 100;
    return stock <= 0
  }).length === 0;

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>Subtotal</TableCell>
              <TableCell align="right"><Subtotal items={items}/></TableCell>
            </TableRow>

            <IfFalse condition={hideDiscount && hideInactive}>
              <TableRow>
                <TableCell colSpan={2}>Discount</TableCell>
                <TableCell align="right"><IfFalse condition={hideDiscount}><Discount items={items}/></IfFalse></TableCell>
              </TableRow>
            </IfFalse>

            <IfFalse condition={hideShipping && hideInactive}>
              <TableRow>
                <TableCell colSpan={2}>Shipping</TableCell>
                <TableCell align="right"><IfFalse condition={hideShipping}><Shipping items={items}/></IfFalse></TableCell>
              </TableRow>
            </IfFalse>

            <IfFalse condition={hideTax && hideInactive}>
              <TableRow>
                <TableCell>Tax</TableCell>
                <TableCell align="right">{`${(TAX_RATE * 100).toFixed(0)}%`}</TableCell>
                <TableCell align="right"><IfFalse condition={hideTax}><Taxes items={items}/></IfFalse></TableCell>
              </TableRow>
            </IfFalse>

            <IfFalse condition={hideTotal && hideInactive}>
            <TableRow>
                <TableCell colSpan={2}>Total</TableCell>
                <TableCell align="right"><IfFalse condition={hideTotal}><Total items={items}/></IfFalse></TableCell>
              </TableRow>
            </IfFalse>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TotalsPanel