
import React, { useContext, useEffect, useState } from 'react';
import API from '../../API';
import { CSVLink, CSVDownload } from 'react-csv'
import ConfigContext from '../../contexts/ConfigContext';

const ExportCsv = () => {
  const {config} = useContext(ConfigContext);
  const [items, setItems] = useState<IItem[]>([])
  const [categories, setCategories] = useState<ICategory[]>([])

  const root = config.general.root_category;

  const fetchItems = async () => {
    try {
      const items = await API.getItems();

      setItems(items);
    }
    catch (err: any) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [])

  function applyRootFilter(category: ICategory, index: number) { 
    return (category.name === root); 
  }  

  const fetchCategories = async () => {
    try {
      const allRoots = await API.getCategories();
      const roots = allRoots.filter(applyRootFilter);
      const myCategories = roots.flatMap(category => {
        return category.children;
      });

      setCategories(myCategories);
    }
    catch (err: any) {
      console.error(err.message);
    }
  }

  const validCategories: Record<string, ICategory> = {};
  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    validCategories[category.id] = category;
  }

  const getPriceString = (price: number) => {
    const dollars = price ? Math.trunc(price/100) : 0;
    const cents = price ? price - (dollars * 100) : 0;
    const centString = cents < 10 ? "0" + cents : cents;
    return "$" + dollars + '.' + centString;
  }

  const trimProductName = (nameAndBrand: string) => {
    let tokens = nameAndBrand.split('>');
    const nameAndVariation = tokens[tokens.length - 1].trim();
    tokens = nameAndVariation.split(' - ');
    const name = tokens[0];
    const shortName = name.length > 26 ? name.substring(0, 23) + "..." : name;
    return shortName.replaceAll('"', '""');
  }

  const csvData = items
    .filter((item) => item && item.categoryId && validCategories[item.categoryId])
    .map((item) => [
      item.id,
      item.codes?.upc ? item.codes?.upc : item.codes?.sku,
      item.brand,
      trimProductName(item.name),
      item.supplierUrls.length > 0 ? item.supplierUrls[0] : '',
      item && item.cost ? getPriceString(item.cost) : "0.00",
      item && item.price ? getPriceString(item.price) : "0.00",
      item.description
    ])

  if (csvData) {
    return (
      <>
        <CSVLink data={csvData}>Download me</CSVLink>;
        {/* <CSVDownload data={csvData} target="_blank" />; */}
      </>
    )
  }

  return (<></>)
}

export default ExportCsv


