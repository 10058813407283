import React, { useContext, useEffect, useState } from 'react';
import CartContext from '../../../../contexts/CartContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ShippingClient from '../../../../api/ShippingClient';
import ConfigContext from '../../../../contexts/ConfigContext';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IfTrue from '../../../conditions/IfTrue';
import IfFalse from '../../../conditions/IfFalse';

const DeliveryAddress = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const tomorrow: Date = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  // Get the cart from the context
  const {cart, setCartState} = useContext(CartContext);
  const [shipping, setShipping] = useState<ShippingDetails|undefined>();

  useEffect(() => {
    const doGetShipping = async() => {
      if (cart.shippingMethod) {
        const shippingDetails = await ShippingClient.getShipping(cart.shippingMethod, cart, 0);
        setShipping(shippingDetails);
      }
    }

    doGetShipping();
  }, [cart.shippingMethod, cart.deliveryAddress]);

  const minDays = shipping?.minDays ? shipping.minDays : 0;
  const maxDays = shipping?.maxDays ? shipping.maxDays : 0;

  const getPickupText = () => {

    return (
      <Card className='delivery-description'>
        <CardContent>
          <div>
            Pick-up is available at The Atlantic Grow Shop, 186 Church Street, Liverpool Nova Scotia.
            A representative will contact you regarding a pickup time.
          </div>
        </CardContent>
      </Card>
    );
  }

  const getLocalText = () => {
    return (
      <Card className='delivery-description'>
        <CardContent>
          <div>
            Local delivery is only available within 40km of The Atlantic Grow Shop, 186 Church Street, Liverpool Nova Scotia at this time.
          </div>
        </CardContent>
      </Card>
    );
  }

  const getGenericText = () => {
    return (
      <Card className='delivery-description'>
        <CardContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Delivery Estimate</FormLabel>
            <div>
              {`${minDays + 1}-${maxDays + 3} days`}
            </div>
          </FormControl>
        </CardContent>
      </Card>
    );  
  }

  const getSelectMethodText = () => {
    return (
      <Card className='delivery-description'>
        <CardContent>
          <div>
            Select a shipping method for more information
          </div>
        </CardContent>
      </Card>
    );
  }

  switch (cart.shippingMethod) {
    case 'PICKUP':
      return getPickupText();
    case 'LOCAL':
      return getLocalText();
    case 'ARMOUR':
      return getGenericText();
    case 'CP.RP':
      return getGenericText();
    case 'CP.EP':
      return getGenericText();
    case 'CP.XP':
      return getGenericText();
    case 'CP.PC':
      return getGenericText();
default:
      return getSelectMethodText();
  }
}

export default DeliveryAddress