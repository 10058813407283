import Price from '../shop/Price';

type Props = {
  discount: number | undefined
}


const InvoiceReviewDiscount = ({ discount }: Props) => { 
  // Get the cart from the context

  if (!discount) {
    return (
      <>
      </>
    )
  }

  return (
    <>
        <div><Price price={discount} showZero={true}/></div>
    </>
  )
}

export default InvoiceReviewDiscount