import axios, { AxiosResponse } from "axios"

class AbstractCommonApi {
    ACTIVE_STORE_KEY: string = "ACTIVE_STORE";
    store: string | undefined = process.env.REACT_APP_DB_NAME as string;
    baseUrl: string = (process.env.REACT_APP_API_HOST as string);
    extendedUrl: string = (process.env.REACT_APP_API_HOST as string) + "/" + this.store;

    public constructor() {
        const myStore = window.localStorage.getItem(this.ACTIVE_STORE_KEY);
    
        if (!myStore) {
          this.store = process.env.REACT_APP_DB_NAME as string;
        }
        else {
          this.store = myStore;
        }
        this.extendedUrl = (process.env.REACT_APP_API_HOST as string) + "/" + this.store;
    }
}

export default AbstractCommonApi;