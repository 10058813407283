import React from 'react';

type Props = {
  children: React.ReactNode;
  condition: boolean | undefined,
}

const IfFalse = ({ condition, children }: Props) => {

  if (condition) {
    return (
      <>
      </>
    )
  }
  return (
    <>
      {children}
    </>
  )
}

export default IfFalse