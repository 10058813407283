import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import InvoiceReviewSubtotal from './InvoiceReviewSubtotal';
import InvoiceReviewDiscount from './InvoiceReviewDiscount';
import InvoiceReviewTaxes from './InvoiceReviewTaxes';
import InvoiceReviewTotal from './InvoiceReviewTotal';

type Props = {
  order: IOrder
}

/**
 * For invoices/orders the shipping is one of the inventory items,
 * so shipping and total are calculated differently (no shipping).
 */
const InvoiceReviewItems = ({ order }: Props) => {
  const TAX_RATE = 0.15;

  const items = order.items;

  return (
    <TableContainer component={Paper}>
    <Table size="small" aria-label="simple table">
      <TableBody>
        <TableRow>
          <TableCell colSpan={2}>Subtotal</TableCell>
          <TableCell align="right"><InvoiceReviewSubtotal items={items}/></TableCell>
        </TableRow>
        <TableRow>
              <TableCell colSpan={2}>Discount ({order.discountCode ? order.discountCode : 'None'})</TableCell>
              <TableCell align="right"><InvoiceReviewDiscount discount={order.discountAmount}/></TableCell>
            </TableRow>
        <TableRow>
          <TableCell>Tax</TableCell>
          <TableCell align="right">{`${(TAX_RATE * 100).toFixed(0)}%`}</TableCell>
          <TableCell align="right"><InvoiceReviewTaxes order={order} ignoreShipping={true}/></TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>Total</TableCell>
          <TableCell align="right"><InvoiceReviewTotal order={order} ignoreShipping={true}/></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);
}

export default InvoiceReviewItems