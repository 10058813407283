import React, { useContext, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import ModifyCartPanel from './ModifyCartPanel';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CartContext from '../../../contexts/CartContext';
import IfTrue from '../../conditions/IfTrue';
import ConfigContext from '../../../contexts/ConfigContext';
import IfFalse from '../../conditions/IfFalse';

const drawerWidth = 480;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
      },
    },
    appBar: {
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    menuButton: {
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      // necessary for content to be below app bar
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      marginLeft: -drawerWidth,
    },
    contentShift: {
      marginLeft: 0,
    },
  }),
);

type Props = {
    cartOpen: boolean,
    setCartOpen: React.Dispatch<React.SetStateAction<boolean>>
  }

export default function CartDrawer({ cartOpen, setCartOpen }: Props) {
    const {config} = useContext(ConfigContext);
    const history = useHistory();
    const classes = useStyles();
    const [goto, setGoto] = useState<string|undefined>();
    const {cart, setCartState} = useContext(CartContext);
    const disabled = config.general.site_disabled;


    const handleDrawerOpen = () => {
        setCartOpen(true);
    };

    const handleDrawerClose = () => {
        setCartOpen(false);
    };

    if (goto && !cartOpen) {
      console.log(`history.push(${goto})`); 
      history.push(goto);
      setGoto(undefined);
      return (<></>);
    }

    const handleModifyCart = () => {
        handleDrawerClose();
        setGoto('/order/cart');
    }

    const handleCheckout = () => {
        handleDrawerClose();
        setGoto('/order/checkout');
    }

return (
  <Drawer
    className={classes.drawer + ' drawer-button cart-drawer'}
    anchor="right"
    open={cartOpen}
    classes={{
      paper: classes.drawerPaper,
    }}
    onClose={handleDrawerClose}
  >
    <div className={classes.drawerHeader}>
      <IconButton onClick={handleDrawerClose} size="large">
        <ChevronRightIcon />
      </IconButton>
    </div>
    <Divider />

    <IfTrue condition={disabled}>
      <div style={{padding: "1em"}}>
      Ordering from the site is temporarily disabled while we perform yearly inventory and
      restock. Please call <b>(902) 343-3569</b> to order by phone with one of our
      super helpful staff. Sorry for the inconvenience.
      </div>
    </IfTrue>

    <IfFalse condition={disabled}>
      <Button className='drawer-button' variant="contained" color="primary" startIcon={<ShoppingCartCheckoutIcon />} onClick={handleCheckout}>
          Checkout
      </Button>
      <Divider />
      <ModifyCartPanel hideDiscount={true} hideTax={true} hideTotal={true} hideInactive={true} hideShipping={!cart.deliveryAddress.postal}/>
    </IfFalse>
  </Drawer>
);
}
 