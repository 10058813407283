import React, { useContext, useEffect } from 'react';
import DeliveryAddress from '../delivery/DeliveryAddress';
import TotalsPanel from '../../cart/TotalsPanel';
import DeliveryOptions from '../delivery/DeliveryOptions';
import ContactPanel from '../ContactPanel';
import DeliveryDescription from '../delivery/DeliveryDescription';
import DeliveryNotes from '../delivery/DeliveryNotes';
import IfTrue from '../../../conditions/IfTrue';
import ConfigContext from '../../../../contexts/ConfigContext';
import IfFalse from '../../../conditions/IfFalse';

type Props = {
  setNextName: React.Dispatch<React.SetStateAction<string>>;
  setPreNextAction: React.Dispatch<React.SetStateAction<() => () => Promise<boolean>>>;
}

const Step3 = ({setNextName, setPreNextAction}: Props) => {
  useEffect(() => {
    setPreNextAction(() => async () => {return true});
    setNextName("Next");
    window.scrollTo(0, 0);
  }, []);

  const {config} = useContext(ConfigContext);
  const shippingOptions: string[] = config.general.shipping;
  const alwaysVisible = shippingOptions.length === 2;

  return (
    <div className='checkout-parent'>
      <div className='checkout-main'>
        <DeliveryAddress/>
        <DeliveryOptions/>
        <DeliveryDescription/>
        <DeliveryNotes/>
      </div>
      <div className='checkout-right'>
        <TotalsPanel hideDiscount={true} />
        <ContactPanel />
      </div>
    </div>
  )
}

export default Step3