import { useContext } from 'react';
import ConfigContext from '../../contexts/ConfigContext';
import ReactMarkdown from 'react-markdown'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const WelcomeText = () => {
    const {config} = useContext(ConfigContext);

  return (
    <div className='beta'>
        <Link to="/category">
          <Button className='shop-button' variant="contained" color="primary">Shop The Back Room</Button>
        </Link>
        <ReactMarkdown>{config.general.welcome_text}</ReactMarkdown>
    </div>
  )
}

export default WelcomeText