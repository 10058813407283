import React, { useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Shipping from '../../cart/Shipping';
import CartContext from '../../../../contexts/CartContext';
import ConfigContext from '../../../../contexts/ConfigContext';
import IfTrue from '../../../conditions/IfTrue';
import IfFalse from '../../../conditions/IfFalse';


const DeliveryOptions = () => {
  const {cart, setCartState} = useContext(CartContext);
  const {config} = useContext(ConfigContext);

  const shippingOptions: string[] = config.general.shipping;

  // If only pickup and local are available, they need to be always visible.
  const alwaysVisible = shippingOptions.length === 2;

  const updateCartField = (name: string, value: any) => {
    setCartState({
      ...cart,
      [name]: value
      });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCartField('shippingMethod', (event.target as HTMLInputElement).value);
  };

  /**
   * 
   * @returns True if there is a valid postal code
   */
  const isValidDeliveryPostalCode = (): boolean => {
    if (!cart.deliveryAddress.postal) {
      return false;
    }
    const code = cart.deliveryAddress.postal.replaceAll(" ", "");
    if (code.length == 6) {
      return true;
    }
    return false;
  }

  const items: ICartItem[] = Object.values(cart.items);

  return (
    <Card className='delivery-options'>
      <CardContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Delivery Options</FormLabel>
          <IfTrue condition={alwaysVisible}>Local shipping only available within 40km of Atlantic Grow Shop</IfTrue>
          <IfFalse condition={isValidDeliveryPostalCode()}>
            Enter valid postal code to see all available delivery options.
          </IfFalse>
          <RadioGroup aria-label="delivery" name="delivery" value={cart.shippingMethod} onChange={handleChange}>
            {shippingOptions.map((option: string) => (
              <Shipping key={option} items={Object.values(cart.items)} radio={true} method={option} alwaysVisible={alwaysVisible}/>
            ))}
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  )
}

export default DeliveryOptions