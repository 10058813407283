import React, { Suspense, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import OpenCartButton from './OpenCartButton';
import { Link, useHistory, useLocation } from "react-router-dom";
import ConfigContext from '../../../contexts/ConfigContext';
import IfTrue from '../../conditions/IfTrue';
import SearchField from './SearchField';

type Props = {
  cartOpen: boolean,
  setCartOpen: React.Dispatch<React.SetStateAction<boolean>>,
  areFiltersOpen: boolean,
  setAreFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>,
  deptOpen: boolean,
  setDeptOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function PrimarySearchAppBar({ deptOpen, setDeptOpen, cartOpen, setCartOpen, areFiltersOpen, setAreFiltersOpen }: Props) {
  const history = useHistory();
  const {config} = useContext(ConfigContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const root = config.general.root_category;
  const location = useLocation();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleFiltersOpen = () => {
    setAreFiltersOpen(!areFiltersOpen);
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLoginMenu = () => {
    handleMenuClose();

    console.log('login!');
    history.push('/members');
  }

  const handleProfileMenu = () => {
    handleMenuClose();

    history.push('/members');
  }

  const handleLogoutMenu = () => {
    handleMenuClose();

    console.log('logout!');
    history.push('/');
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const openCartDrawer = (event: React.MouseEvent<HTMLElement>) => {
    setCartOpen(true);
  }

  const fontStyle = {} as React.CSSProperties;
  if (config.general?.fonts?.title) {
    fontStyle['fontFamily'] = config.general.fonts.title;
  }

  const logoStyle = {} as React.CSSProperties;
  if (config.general?.logo?.left) {
    logoStyle['left'] = config.general.logo.left;
    logoStyle['top'] = config.general.logo.top;
    logoStyle['height'] = config.general.logo.height;
  }



  return (
    <div className="header-grow">
      <div className="header-logo">
        <Link to='/' className='business-title business-name'>
          <img style={logoStyle} alt={root} src={'https://thequeensflorist.sirv.com/' + root + '/logos/icon-small.png'}/>
        </Link>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <AppBar position="static">
          <Toolbar className='header-toolbar'>
            <div className='hamburger'>
            </div>
            <div className="header-title">
              <div>
              <Link to='/' className='business-title business-name' style={fontStyle}>
                  {config.general.product_name}
              </Link>
              </div>
              <IfTrue condition={config.general.product_subtext}>
                <div className='business-subname' style={fontStyle}>
                <Link to='/' className='business-title'>
                    {config.general.product_subtext}
                </Link>
                </div>
              </IfTrue>
            </div>
            <SearchField display='desktop'/>
            <div className="header-section-desktop">
              <OpenCartButton onClick={openCartDrawer}/>
            </div>
            <div className="header-section-mobile">
              <OpenCartButton onClick={openCartDrawer}/>
            </div>
          </Toolbar>
        </AppBar>
      </Suspense>
    </div>
  );
}
