import Special from './Special';

type Props = {
  count?: number,
  ignoreText?: boolean,
  special?: boolean
}

const DeliveryButton = ({ count, ignoreText, special }: Props) => {

  if (special) {
    return (
      <Special ignoreText={ignoreText}/>
    )
  }
  return (<></>)
}

export default DeliveryButton