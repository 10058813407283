import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import CategoryChildPanel from './CategoryChildPanel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CategoryContext from '../../../contexts/CategoryContext';

type Props = {
  group?: string
  selectedCategory?: string
  category: ICategory
}

const CategoryWidget = ({ category, group, selectedCategory }: Props) => {
  const [addingCategory, setAddingCategory] = useState<boolean>(false);
  const [renamingCategory, setRenamingCategory] = useState<boolean>(false);
  const [deleteCategoryToggle, setDeleteCategoryToggle] = useState<boolean>(false);
  const [open, setOpen] = React.useState<string>('');
  const { doCategoryRefresh } = useContext(CategoryContext);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen('');
  };

  // Clicked on a group
  if (category.children.length > 0) {
    const url = "/category/" + category.shortName;
    return <>
      <Snackbar open={!!open} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
        <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="error">
          Error: {open}
        </Alert>
      </Snackbar>

      <div className="category-widget">
        <Link to={url}>{category.shortName}</Link>
      </div>
      <CategoryChildPanel children={category.children} group={group} selectedCategory={selectedCategory}/>
    </>;
  }

  // Clicked on a category
  const url = "/category/" + (group ? group + '/' : '') + category.id;
  
  if (selectedCategory === category.id) {
    return <>
      <Snackbar open={!!open} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
        <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="error">
          Error: {open}
        </Alert>
      </Snackbar>

        <div className="category-widget selected-category"><FontAwesomeIcon icon={faChevronRight} title={category.shortName} />
          &nbsp;{category.shortName}
        </div>
    </>;
  }

  return <>
      <Snackbar open={!!open} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
        <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="error">
          Error: {open}
        </Alert>
      </Snackbar>
      <div className="category-widget">
        <Link to={url}>{category.shortName}</Link>
      </div>
  </>;
}

export default CategoryWidget
