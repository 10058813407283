import AppBar from '@mui/material/AppBar';
import { Link } from "react-router-dom";
import { useContext } from 'react';
import ConfigContext from '../../contexts/ConfigContext';

const Footer = () => {
  const {config} = useContext(ConfigContext);

  return (
    <div id="footer">
      <AppBar position="static" color="default">
        <div className="footer-item">
          <div>
          <span className='business-name'>{config.general.product_name}</span> is brought to you by
          </div>
          <div className='logo'>
          <img src={'https://thequeensflorist.sirv.com/The%20Back%20Room/logos/ags-short.png'}
            alt="The Atlantic Grow Shop"
            title="The Atlantic Grow Shop"
            width="250px"/>
          </div>
        </div>
        <div className="footer-item">
          <div><Link to='/privacy'>Privacy Policy</Link></div>
          <div><Link to='/tos'>Terms of Service</Link></div>
          <div><Link to='/contact'>Contact</Link></div>
        </div>
        <div className="footer-item">
          <div><Link to='/payment'>Payment</Link></div>
          <div><Link to='/ordering'>Ordering</Link></div>
          <div><Link to='/shipping'>Shipping</Link></div>
          <div><Link to='/returns'>Returns</Link></div>
        </div>
        <div className="footer-item">
          <div className="address">
            <div>186 Church Street</div>
            <div>Post Office Box 74</div>
            <div>Liverpool, Nova Scotia</div>
            <div>Canada. B0T 1K0</div>
          </div>
        </div>
      </AppBar>
    </div>
  )
}

export default Footer;