import React, { useContext } from 'react';
import CartContext from '../../contexts/CartContext';
import ConfigContext from '../../contexts/ConfigContext';


/**
 * Gather whatever identity information we can and make it
 * available.
 */
const Ungated : React.FunctionComponent<{}> = props  => {
    const {config} = useContext(ConfigContext);
    const {cart, setCartState} = useContext(CartContext);

    if (config.general.age_check && !cart.customerAddress.ageCheck) {
        return (
            <>
            {props.children}
            </>
        )
    }

    return (
        <>
        </>
    )

}

export default Ungated
