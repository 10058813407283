import Price from './Price'

type Props = {
  priceLow: number,
  priceHigh: number
}

const PriceRange = ({ priceLow, priceHigh }: Props) => {

  if (priceLow === priceHigh) {
    return (
      <Price price={priceLow}/>
    )
  }

  return (
    <><Price price={priceLow}/> - <Price price={priceHigh}/></>
  )

}

export default PriceRange
