import React, { useContext } from 'react';
import CartContext from '../../../contexts/CartContext';
import { Badge, IconButton } from '@mui/material'
import ShoppingCart from '@mui/icons-material/ShoppingCart'
import { useLocation } from 'react-router-dom';

type Props = {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
}

const OpenCartButton = ({ onClick }: Props) => {
    let location = useLocation();

    // Get the cart from the context
    const {cart, setCartState} = useContext(CartContext);
    const items: ICartItem[] = Object.values(cart.items);
    const count: number = items.reduce((count, item) => (+count) + (+item.count), 0);

    let disabled = false;

    if (location.pathname.endsWith('checkout') ||
        location.pathname.endsWith('cart')) {
            disabled = true;
        }

    if (count < 1) {
        return (
            <IconButton
                edge="end"
                aria-label="shopping cart"
                color="inherit"
                onClick={onClick}
                disabled={disabled}
                size="large">
                <ShoppingCart />
            </IconButton>
        );
    }
    return (
        <IconButton
            edge="end"
            aria-label="shopping cart"
            color="inherit"
            onClick={onClick}
            disabled={disabled}
            size="large">
            <Badge badgeContent={count} color="secondary">
                <ShoppingCart />
            </Badge>
        </IconButton>
    );
}

export default OpenCartButton
