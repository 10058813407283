import { SelectChangeEvent, Typography } from '@mui/material';
import InvoiceReviewItems from './InvoiceReviewItems';
import InvoiceReviewTotals from './InvoiceReviewTotals';
import Alert from '@mui/material/Alert';
import IfTrue from '../../conditions/IfTrue';

type Props = {
  order: IOrder,
  handleStateChange?: (event: SelectChangeEvent<{ value: unknown }>) => void
}

const InvoiceReviewPanel = ({ order, handleStateChange }: Props) => {

  return (
    <>
      <Typography variant="h5" component="h2">Order/Invoice {order.id} summary</Typography>
      <Alert severity="info">
        Order state: {order.state}
        <IfTrue condition={order.state === "OPEN"}>
          <p/>
          <div>Please e-transfer total amount to billing@atlanticgrowshop.ca</div>
          <div>or arrange payment at (902) 343-3569.</div>
        </IfTrue>
      </Alert>
      <InvoiceReviewItems order={order}/>
      <InvoiceReviewTotals order={order}/>
    </>
  );
}

export default InvoiceReviewPanel