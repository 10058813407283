import { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import API from '../../API';
import Brand from '../widgets/brands/Brand';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ItemImage from '../widgets/shop/ItemImage';
import React from 'react';
import Button from '@mui/material/Button';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import CartContext from '../../contexts/CartContext';
import CountInput from '../widgets/inputs/CountInput';
import ItemNotes from '../widgets/details/ItemNotes';
import PriceSummary from '../widgets/shop/PriceSummary';
import IfFalse from '../conditions/IfFalse';
import IfTrue from '../conditions/IfTrue';
import VariantSelector from '../widgets/details/VariantSelector';
import Price from '../widgets/shop/Price';
import { Alert, IconButton } from '@mui/material';
import BreadCrumbs from '../widgets/BreadCrumbs';
import DeliveryNotes from '../widgets/details/DeliveryNotes';
import Snackbar from '@mui/material/Snackbar';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ConfigContext from '../../contexts/ConfigContext';
import ReactMarkdown from 'react-markdown'

const ItemDetailsPage = () => {
  const {config} = useContext(ConfigContext);
  let { itemId } = useParams<{itemId: string}>();
  const [item, setItem] = useState<IItem>({
      id: '',
      name: '',
      description: '',
      imageId: '',
      categoryId: '',
      supplierUrls: [],
      homeUrls: [],
      externalId: {},
      inventory: {}
  });

  const [reloadPage, setReloadPage] = React.useState<boolean>(true);
  const [selectedVariation, setSelectedVariation] = React.useState<IItem>(item);
  const [variants, setVariants] = useState<IItem[]>([selectedVariation]);

  // Get the cart from the context
  const {cart, setCartState} = useContext(CartContext);
  
  const [itemCount, setItemCount] = React.useState<number>(1);

  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [checkoutEnabled, setCheckoutEnabled] = React.useState(false);
  const [goto, setGoto] = useState<string|undefined>();

  /**
   * From materialUI "Integration with 3rd party input libraries":
   *   https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
   */
  const handleChange = (value: number) => {
    setItemCount(value);
  };
 
  useEffect(() => {
    const fetchItem = async () => {
      try {
        const item = await API.getItemById(itemId, undefined);
        setItem(item);
        setSelectedVariation(item);

        if (item.group?.enabled && !item.group.variantName) {
          // Download the information about the variants as well.
          setVariants(await API.getVariantsById(itemId));
        }
        else {
          // This is its own variant
          setVariants([item]);
        }
      }
      catch (err: any) {
        setItem({
          id: 'ERROR',
          name: err.message,
          categoryId: '',
          supplierUrls: [],
          homeUrls: [],
          externalId: {},
          inventory: {}
        });
        console.error(err);
      }
    }
    if (itemId) {
      setItem({
        id: '',
        name: '',
        description: '',
        imageId: '',
        categoryId: '',
        supplierUrls: [],
        homeUrls: [],
        externalId: {},
        inventory: {}
    });
      fetchItem();
    }
    setCheckoutEnabled(false);
  }, [itemId, reloadPage]);

  if (goto) {
    console.log("GOTO!");

    window.document.body.style.overflow = 'auto';
    console.log(`history.push(${goto})`);
    history.push(goto);
    return (<></>);
  }

  if (!itemId) {
    window.document.body.style.overflow = 'auto';
    return (
      <>
      </>
    )
  }

  const selectedImageId = selectedVariation.imageId ? selectedVariation.imageId : item.imageId;

  window.document.body.style.overflow = 'hidden';

  if (!item.id) {
    return (
      <div id="id-main">
        Loading item: {itemId}
      </div>
    )
  }

  if ("ERROR" === item.id) {
    return (
      <div id="id-main">
        Error loading item: {item.name}
      </div>
    )
  }

  const addToCart = () => {
    console.log("ADD TO CART");
    const useItem = selectedVariation ? selectedVariation : item;
    const count = cart.items[useItem.id] ? cart.items[useItem.id].count : 0;
    const newCart = {
      ...cart,
      items: {
        ...cart.items,
        [useItem.id]: {item: useItem, count: (+count) + (+itemCount)}
      }
    };
    setCartState(newCart);
    setOpen(true);
    setCheckoutEnabled(true);
  }

  const goBack = () => {
    console.log("HANDLE BACK");
    window.document.body.style.overflow = 'auto';
    if (location.state && (location.state as any).from) {
      history.goBack();
    }
    else {
      history.push('/');
      history.go(0);
    }
  }

  const handleClose = (reason?: string) => {
    console.log("HANDLE CLOSE");
    window.document.body.style.overflow = 'auto';
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCheckout = () => {
    console.log("HANDLE CHECKOUT");
    setGoto('/order/checkout');
  }

  const handleModifyCart = () => {
    setGoto('/order/cart');
  }

  const stockCounter = Object.keys(item.inventory).reduce((sum, key) => sum + item.inventory[key].count, 0);
  const disabled = config.general.site_disabled;

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
        <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="success">
          Added {item.name} to cart.
        </Alert>
      </Snackbar>
      <div className="overlay" onClick={goBack}></div>
      <div id="id-main">
        <div className="header-bar">
          <BreadCrumbs item={item} variant={selectedVariation}/>
          <div className="spacer"></div>
          <IconButton
            className="close-icon"
            aria-label="back"
            onClick={goBack}
            size="large">
            <CloseIcon />
          </IconButton>
        </div>
        <div className="dialog-body">
          <div className='hlayout'>
            <Brand name={item.brand ? item.brand : ''} />
          </div>
          <div className='id-title'>{item.name}</div>
          <div className="id-content">
            <Card className='id-image'>
              <CardContent>
              <ItemImage notLazy={true} width={400} id={selectedImageId} title={item.name} timestamp={item.updatedAt?.toString()}/>
              </CardContent>
            </Card>
            <div className='id-text-content'>
              <div>
              <ReactMarkdown>{selectedVariation.description ? selectedVariation.description : (item.description ? item.description : '')}</ReactMarkdown>
              </div>
              <div>
                <div>
                  <IfTrue condition={item.group?.enabled && item.id === selectedVariation.id}>
                    <PriceSummary variations={variants}/>
                  </IfTrue>
                  <IfFalse condition={item.group?.enabled && item.id === selectedVariation.id}>
                    <Price variation={selectedVariation}/>

                    <DeliveryNotes stock={stockCounter} specialDelivery={!!item.special}/>
                  </IfFalse>
                </div>
              </div>
              <ItemNotes notes={selectedVariation.notes ? selectedVariation.notes : (item.notes ? item.notes : '')}/>
              <div>
                <input type="hidden" id="purchase-variation" name="variation" value={selectedVariation.id}></input>
              </div>
              <div>
                <IfTrue condition={item.group?.enabled && !item.group.variantName}>
                  <VariantSelector item={item} variants={variants} setSelectedVariation={setSelectedVariation}/>
                </IfTrue>
              </div>
              <IfTrue condition={item.discontinued}>
                <Alert severity="error">
                  <div className='discontinued-text'>This item has been discontinued: {stockCounter} left in stock</div>
                </Alert>
              </IfTrue>

              <IfTrue condition={disabled}>
                <Alert severity="error">
                  Ordering from the site is temporarily disabled while we perform yearly inventory and
                  restock. Please call <b>(902) 343-3569</b> to order by phone with one of our
                  super helpful staff. Sorry for the inconvenience.
                </Alert>
              </IfTrue>

              <div>
                <div>
                  <IfFalse condition={selectedVariation.group?.enabled && !selectedVariation.group.variantName}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddShoppingCartIcon />}
                      disabled={
                          !(+itemCount > 0)
                        || (!selectedVariation.id)
                        || disabled}
                      onClick={addToCart}>
                      Add to Cart
                    </Button>
                    <CountInput handleCommit={handleChange} initial={+itemCount}/>
                  </IfFalse>
                  </div>
                <div className='flex-div flex-spacing'>
                  <IfTrue condition={checkoutEnabled}>
                    {/* <Button className='drawer-button' variant="outlined" color="secondary" startIcon={<ShoppingCartIcon />} onClick={handleModifyCart}>
                        View Cart
                    </Button> */}
                    <Button className='drawer-button' variant="outlined" color="primary" startIcon={<ShoppingCartCheckoutIcon />} onClick={handleCheckout}>
                        Checkout
                    </Button>
                  </IfTrue>
                </div>
              </div>
            </div>
          </div>
          {/* <pre>
            {JSON.stringify(item, null, 1)}
          </pre> */}
        </div>
      </div>
    </>
  );
}

export default ItemDetailsPage