
// import React from 'react';


const Florist = () => {
  return (
    <div className="store-parent">
      <div className="florist-main">
        <h2>Our House Florist</h2>

        <div>
          Our House has not one, but two in-house florists!

          All of our arrangements are custom made to order, and we can cater to any budget.
          We have a wide range of flowers, and we can create arrangements for any occasion.
        </div>
        <div>
          We also offer a delivery service, so you can have your flowers delivered to your door.
          We can deliver to Liverpool and the surrounding areas (Queens County). We can deliver
          gifts and other items along with your flowers as well.
        </div>
        <div>
          Call now to talk to a florist and place your order: <b>(902) 343-3569</b>
        </div>
      </div>
    </div>
  )
}

export default Florist


