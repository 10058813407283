import { useContext, useEffect, useState } from 'react';
import CategoryContext, { CategoryState } from '../../../contexts/CategoryContext';
import CategoryWidget from './CategoryWidget'
import { Link } from "react-router-dom";
import { Divider, Drawer, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


type Props = {
  root: string
  group?: string
  selectedCategory?: string
  display?: string
  setDisplay?: React.Dispatch<React.SetStateAction<string>>
}

const CategoryPanel = ({ root, group, display, setDisplay, selectedCategory }: Props) => {
  const { category, doCategoryRefresh } = useContext(CategoryContext);
  const [addingCategory, setAddingCategory] = useState<boolean>(false);

  const categoryState: CategoryState = category;
  const categories = categoryState.categories;


  return (
    <div id="category-placement" style={{display}}>
      <div id="category-drawer">
      <div id="category-drawer-header">
        <IconButton
          onClick={() => {if (setDisplay) {setDisplay('none')}}}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <div>
      </div>
      <div className="categories">
        <div>
          <Link to='/category/'>All Departments</Link>
        </div>
        <ul className="groups">
        {categories.map((category: ICategory) => (
          <CategoryWidget
            key={category.id}
            category = {category}
            group={group}
            selectedCategory={selectedCategory}
        />
        ))}
        </ul>
      </div>
      </div>
    </div>
  );
}

export default CategoryPanel