import React, { useEffect, useContext } from 'react';
import API from '../../API';
import ConfigContext from '../../contexts/ConfigContext';

/**
 * Gather whatever identity information we can and make it
 * available.
 */
const ConfigTemplate : React.FunctionComponent<{}> = props  => {
    const {config, setConfig} = useContext(ConfigContext);

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const myConfig = await API.getClientConfig("client");
                setConfig(myConfig);
            }
            catch (err: any) {
                console.error(err.message);
            }
        }
        
        fetchConfig();
    }, [])

    if (config.general) {
        return (
            <>
                {props.children}
            </>
        )
    }
    
    else {
        return (
            <>
                Loading...
            </>
        )
    }
}

export default ConfigTemplate
