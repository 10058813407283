import React, { useContext, useEffect } from 'react';
import PrimarySearchAppBar from './quickbar/PrimarySearchAppBar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useHistory } from 'react-router-dom';
import ConfigContext from '../../contexts/ConfigContext';
import SearchField from './quickbar/SearchField';
import { Helmet } from 'react-helmet';

type Props = {
}

const Style = ({  }: Props) => {
  const {config} = useContext(ConfigContext);

  useEffect(() => {
    if (config?.general?.product_name) {
      document.title = config.general.product_name;
    }
  }, [config?.general?.product_name]);

  const subtitle = config?.general?.product_subtext;
  const title = subtitle ? config?.general?.product_name + " - " + config?.general?.product_subtext : config?.general?.product_name;

  const getBrandStyle = (path: string) => {
    return `/css/${config?.general?.style}/${path}`;
  }

  const style = config?.general?.style

  if (!style) {
    return (<Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={config?.general?.description}/>
    </Helmet>)
  }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={config?.general?.description}/>

      {/* Legacy CSS */}
      <link rel="stylesheet" href="/css/fonts.css"/>
      <link rel="stylesheet" href="/css/index.css"/>
      <link rel="stylesheet" href="/css/departments.css"/>
      <link rel="stylesheet" href="/css/store.css"/>
      <link rel="stylesheet" href="/css/card.css"/>
      <link rel="stylesheet" href="/css/categories.css"/>
      <link rel="stylesheet" href="/css/quickbar.css"/>
      <link rel="stylesheet" href="/css/menubar.css"/>
      <link rel="stylesheet" href="/css/footer.css"/>
      <link rel="stylesheet" href="/css/itemdetails.css"/>
      <link rel="stylesheet" href="/css/cartdrawer.css"/>
      <link rel="stylesheet" href="/css/checkout.css"/>

      {/* Default CSS */}
      <link rel="stylesheet" href={getBrandStyle("default/header.css")} />
      <link rel="stylesheet" href={getBrandStyle("default/menu.css")} />
      <link rel="stylesheet" href={getBrandStyle("default/categories.css")} />
      <link rel="stylesheet" href={getBrandStyle("default/itemdetails.css")} />
      <link rel="stylesheet" href={getBrandStyle("default/cart.css")} />
      <link rel="stylesheet" href={getBrandStyle("default/ageGate.css")} />
      <link rel="stylesheet" href={getBrandStyle("default/store.css")} />
      <link rel="stylesheet" href={getBrandStyle("default/footer.css")} />

      {/* Tablet CSS */}
      <link rel="stylesheet" href={getBrandStyle("tablet/header-1024.css")} />
      <link rel="stylesheet" href={getBrandStyle("tablet/menu-1024.css")} />
      <link rel="stylesheet" href={getBrandStyle("tablet/menu-800.css")} />
      <link rel="stylesheet" href={getBrandStyle("tablet/categories-800.css")} />
      <link rel="stylesheet" href={getBrandStyle("tablet/itemdetails-1024.css")} />

      {/* Mobile CSS */}
      <link rel="stylesheet" href={getBrandStyle("mobile/header-640.css")} />
      <link rel="stylesheet" href={getBrandStyle("mobile/header-480.css")} />
      <link rel="stylesheet" href={getBrandStyle("mobile/menu-640.css")} />
      <link rel="stylesheet" href={getBrandStyle("mobile/categories-640.css")} />
      <link rel="stylesheet" href={getBrandStyle("mobile/categories-480.css")} />
      <link rel="stylesheet" href={getBrandStyle("mobile/cart-640.css")} />
      <link rel="stylesheet" href={getBrandStyle("mobile/cart-480.css")} />
      <link rel="stylesheet" href={getBrandStyle("mobile/store-480.css")} />
      <link rel="stylesheet" href={getBrandStyle("mobile/store-640.css")} />
    </Helmet>
  )
}

export default Style;