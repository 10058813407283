import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ItemImage from '../shop/ItemImage';
import Price from '../shop/Price';
import CountInput from '../../widgets/inputs/CountInput';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CartItemLink from './CartItemLink';
import IfFalse from '../../conditions/IfFalse';
import DeliveryButton from '../checkout/delivery/description/DeliveryButton';

type Props = {
  item: ICartItem,
  handleChange?: (id: string, count: number) => void,
  readonly?: boolean,
  handleDelete?: (id: string) => void
}

const ModifyCartItem = ({ item, handleChange, readonly, handleDelete }: Props) => {

  const myHandleChange = (value: number) => {
    if (handleChange) {
      handleChange(item.item.id, value);
    }
  }

  const myDelete = () => {
    if (handleDelete) {
      handleDelete(item.item.id);
    }
  }

  // We need to handle situations where the inventory has not been updated to the new inventory style (florist!)
  const hasInventory = !!item.item.inventory

  // If inventory is old style, then assume we have some left
  const stock = hasInventory ? Object.keys(item.item.inventory).reduce((sum, key) => sum + item.item.inventory[key].count, 0) : 100;
  const special = !!item.item.special;

  return (
    <TableRow className='items-table-row' key={item.item.id}>
      <TableCell className='table-icons items-table-cell'>
        <div className='icon-row'>
          <IconButton
            title='Remove item(s) from cart'
            disabled={readonly}
            className='delete-button'
            aria-label="delete"
            onClick={myDelete}
            size="large">
            <DeleteIcon />
          </IconButton>
          <IfFalse condition={stock === undefined}>
            <DeliveryButton special={special} count={stock} ignoreText={true}/>
          </IfFalse>
        </div>
      </TableCell>
      <TableCell className='noPadding items-table-cell items-image-cell'>
        <ItemImage width={80} height={80} id={item.item.imageId} title={item.item.name}/>
      </TableCell>
      <TableCell className='items-table-cell' component="th" scope="row">
        <CartItemLink id={item.item.id} name={item.item.name}/>
        <div><Price price={item.item.price ? item.item.price : 0}/> ea.</div>
      </TableCell>
      <TableCell className='items-table-cell items-table-cell-quantity'>
        <CountInput handleCommit={myHandleChange} initial={item.count} readonly={readonly} layout='vertical'/>
      </TableCell>
      <TableCell className='items-table-cell' align="right"><Price price={(item.item.price ? item.item.price : 0) * item.count}/></TableCell>
    </TableRow>
  );
}

export default ModifyCartItem