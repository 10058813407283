import { useContext, useEffect, useState } from 'react';
import ConfigContext from '../../../../../contexts/ConfigContext';

declare global {
  interface Window {
    Square:any;
  }
}

const PAYMENT_PROCESSOR_NAME = 'square';
const SQUARE_API_ID = 'squareapi';
const PAYMENT_STATUS_CONTAINER = 'square-payment-status-container';
const CARD_BUTTON = 'square-card-button';
const CARD_CONTAINER = 'square-card-container';

const loadSquareApi = (callback: any) => {
  const existingScript = document.getElementById(SQUARE_API_ID);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://sandbox.web.squarecdn.com/v1/square.js';
    script.id = SQUARE_API_ID;
    document.body.appendChild(script);
    script.onload = () => { 
      if (callback) callback();
    };
  }
  if (existingScript && callback) {
    callback();
  }
};

type Props = {
  setGetPaymentToken: React.Dispatch<React.SetStateAction<undefined | (() => () => Promise<any>)>>;
}

const SquareupPaymentProvider = ({setGetPaymentToken}: Props) => {
  const {config} = useContext(ConfigContext);

  const [squareLoaded, setSquareLoaded] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<any>(undefined);

  /**
   * Load the square API
   * 
   * https://betterprogramming.pub/loading-third-party-scripts-dynamically-in-reactjs-458c41a7013d
   */
  useEffect(() => {
    loadSquareApi(() => {
      setSquareLoaded(true);
    });
  }, []);

  console.log("PAYMENT METHOD: " + paymentMethod);

  /**
   * Once the square API is loaded, initialize the payment stuff
   * 
   * https://developer.squareup.com/docs/web-payments/take-card-payment
   */
  useEffect(() => {
    async function loadSquarePayment() {
      if (paymentMethod) {
        await paymentMethod.destroy();
        setPaymentMethod(undefined);
      }

      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }
      let payments;
      try {
        payments = window.Square.payments(config.square.application_id, config.square.location_id);
      } catch (e: any) {
        console.error('Error loading payments', e);
        return;
      }

      try {
        setPaymentMethod(await initializeCard(payments));
      } catch (e: any) {
        console.error('Initializing Card failed', e);
        return;
      }
    }

    if (squareLoaded) {
      loadSquarePayment();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.square.application_id, config.square.location_id, squareLoaded]);

  /**
   * Initialize the card input fields
   * 
   * https://developer.squareup.com/docs/web-payments/take-card-payment
   */
  async function initializeCard(payments: any) {
    const card = await payments.card();
    await card.attach('#' + CARD_CONTAINER); 
    return card; 
  }


  /**
   * This function tokenizes a payment method.
   * The ‘error’ thrown from this async function denotes a failed tokenization,
   * which is due to buyer error (such as an expired card). It is up to the
   * developer to handle the error and provide the buyer the chance to fix
   * their mistakes.
   * 
   * https://developer.squareup.com/docs/web-payments/take-card-payment
   */
  const tokenize = async(): Promise<any> => {
    console.log("Tokenizing: " + paymentMethod);
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return {
        provider: PAYMENT_PROCESSOR_NAME,
        location: config.square.location_id,
        token: tokenResult.token
      }

    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  }

  /**
   * Helper method for displaying the Payment Status on the screen.
   * status is either SUCCESS or FAILURE;
   * 
   * https://developer.squareup.com/docs/web-payments/take-card-payment
   */
  function displayPaymentResults(status: string) {
    const statusContainer = document.getElementById(PAYMENT_STATUS_CONTAINER);

    if (statusContainer) { // This should always be true
      if (status === 'SUCCESS') {
        statusContainer.classList.remove('is-failure');
        statusContainer.classList.add('is-success');
      } else {
        statusContainer.classList.remove('is-success');
        statusContainer.classList.add('is-failure');
      }

      statusContainer.style.visibility = 'visible';
    }
  }

  /**
   * Add the following code to the DOMContentLoaded EventListener in step 3.3 by replacing
   * `// Step 5.2: create card payment` with the following code:
   */
  async function handlePaymentMethodSubmission() {
    // event.preventDefault();

    // try {
    //   // disable the submit button as we await tokenization and make a
    //   // payment request.
    //   setButtonDisabled(true);
    //   const token = await tokenize();
    //   const paymentResults = await createPayment(token);
    //   displayPaymentResults('SUCCESS');

    //   console.debug('Payment Success', paymentResults);
    // } catch (e: any) {
    //   setButtonDisabled(false);
    //   displayPaymentResults('FAILURE');
    //   console.error(e.message);
    // }
  }

  useEffect(() => {
    if (paymentMethod) {
      setGetPaymentToken(() => tokenize);
    }
  }, [paymentMethod]);


  return (
    <>
    <div>
      <form id="square-payment-form">
        <div id={CARD_CONTAINER}></div>
        {/* <button
          id={CARD_BUTTON}
          type="button"
          onClick={handlePaymentMethodSubmission}
          disabled={buttonDisabled}>Pay $1.00</button> */}
      </form>
      <div id={PAYMENT_STATUS_CONTAINER}></div>
    </div>
    </>
  )
}

export default SquareupPaymentProvider